import { ChangeDetectionStrategy, Component, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor} from '@angular/forms';

@Component({
  selector: 'app-base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseInputComponent implements ControlValueAccessor, OnInit {

  @Input() title!: string;
  @Input() formControlName: string;
  @Input() readonly: boolean;

  formController: AbstractControl;

  isDisabled = false;

  private _value: any;
  public get value(): any {
    return this._value;
  }

  public set value(v: any) {
    if (v !== this._value) {
      this.writeValue(v);
    }
  }

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer
  ) {
  }

  ngOnInit() {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.formController = this.controlContainer.control.get(this.formControlName);
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  writeValue(obj: any): void {
    this._value = obj;
    this.onChange(this.value);

    // if (this.formController && this.formController.touched) {
    //   this.formController.markAsUntouched();
    // }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  isRequiredError = (): boolean => this.formController?.errors ? this.formController.errors.required : false;

}
