import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DonatesHttpRequestsService {

  baseUrl = environment.api4Url + '/donates';

  constructor(private httpClient: HttpClient) {
  }


  getDonateLink(amount: number, currency: string): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(`${this.baseUrl}/getDonateLink?amount=${amount}&currency=${currency}&source=app`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  getDonateInfo(paymentId: string): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(`${this.baseUrl}/getDonateInfo?paymentId=${paymentId}`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }
}
