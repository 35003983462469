import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private _toastController: ToastController,
  ) { }

  async show(params: {
    message: string,
    duration?:number,
    position?:'top'|'middle'|'bottom',
    color?: string
  }): Promise<HTMLIonToastElement>{
    const topToast = await this._toastController.getTop();
    if(topToast){
      topToast.dismiss();
    }
    params.duration = params.duration ?? 5000;
    params.position = params.position ?? 'bottom';
    params.color = params.color ?? 'primary';
    return this._toastController.create({
      mode: 'ios',
      message: params.message,
      duration: params.duration,
      position: params.position,
      color: params.color
    }).then(toast=>{
      toast.present();
      return toast;
    });
  }

  showError(text:string='Ошибка! Проверьте соединение с интернетом и повторите попытку позже.'){
    this.show({
      message: text,
      color: 'danger',
      duration: 3000
    });
  }
}
