import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shuffle' })
export class ShufflePipe implements PipeTransform {
  transform(array: any[]): any[] {
    const newArray =  [...array];

    let currentIndex = newArray.length;
    let temporaryValue: any;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = newArray[currentIndex];
      newArray[currentIndex] = newArray[randomIndex];
      newArray[randomIndex] = temporaryValue;
    }

    return [...newArray];
  }
}
