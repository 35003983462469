import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit {

  @Input() lines: 'inset' | 'full' | 'none' = 'inset';
  @Input() options: {
    text: string,
    action: string,
    icon?: string,
  }[];

  constructor(
    private _popoverController: PopoverController
  ) { }

  select(action: string){
    this._popoverController.dismiss({
      action: action
    },'selected');
  }

  ngOnInit() {}

}
