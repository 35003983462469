import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { StringTrimPipe } from './pipes/string-trim.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { MainToolbarComponent } from './main-toolbar/main-toolbar.component';
import { PalmProgressComponent } from './palm-progress/palm-progress.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ScoreBadgeComponent } from './score-badge/score-badge.component';
import { ExpandableItemComponent } from './expandable-item/expandable-item.component';
import { PopoverMenuComponent } from './popoverMenu/popover-menu.component';
import { AttentionMarkerComponent } from './attention-marker/attention-marker.component';
import { VideoThumbnailComponent } from './video-thumbnail/video-thumbnail.component';
import { QuestionComponent } from './question/question.component';
import { DotComponent } from './dot/dot.component';
import { LoadingPanelComponent } from './loading-panel/loading-panel.component';
import { FetchErrorPanelComponent } from './fetch-error-panel/fetch-error-panel.component';
import { PluralPipe } from './pipes/plural.pipe';
import { NiceDatePipe } from './pipes/nice-date.pipe';
import { MatchMarkerPipe } from './pipes/match-marker.pipe';
import { StripTagsPipe } from './pipes/strip-tags.pipe';
import { TrimAroundPipe } from './pipes/trim-around.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchResultComponent } from './main-toolbar/search-result/search-result.component';
import { MainPanelImageComponent } from './main-toolbar/main-panel-image/main-panel-image.component';
import { PromoModalComponent } from './promo-modal/promo-modal.component';
import {CourseCertificateCardComponent} from './course-certificates/course-certificate-card/course-certificate-card.component';
import {CourseCertificateModalComponent} from './course-certificates/course-certificate-modal/course-certificate-modal.component';
import {
  CourseCertificatePaymentProgressComponent
} from './course-certificates/course-certificate-payment-progress/course-certificate-payment-progress.component';
import {ShareModalComponent} from './share-modal/share-modal.component';
import { DragToScrollDirective } from './drag-to-scroll-directive/drag-to-scroll.directive';
import {ScrollShadowDirective} from './scroll-shadow-directive/scroll-shadow-directive';
import {CourseCardComponent} from './course-card/course-card.component';
import { SkeletonLoadingDirective } from './skeleton-loading-directive/skeleton-loading.directive';
import {SkeletonLoadingComponent} from './skeleton-loading/skeleton-loading.component';
import {AboutTeacherModalComponent} from './about-teacher-modal/about-teacher-modal.component';
import {SafeHtmlPipe} from './pipes/safeHtml.pipe';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { ObservableContextDirective } from './observavle-context-directive/observable-context.directive';
import { ShufflePipe } from './pipes/shuffle-array.pipe';
import { TextFieldComponent } from './inputs/text-field/text-field.component';
import { BaseInputComponent } from './inputs/base-input/base-input.component';
import { FieldErrorMessageComponent } from './inputs/field-error-message/field-error-message.component';
import { SettingsModalComponent } from '../play/settings-modal/settings-modal.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CoursesRecommendationCardComponent } from './courses-category-card/courses-recommendation-card.component';
import { ToArrayPipe } from './to-array.pipe';
import { CoursePurchasingModalComponent } from './course-purchasing-modal/course-purchasing-modal.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { AutoFocus } from './autofocus-directive/autofocus.directive';

@NgModule({
  declarations: [
    CardComponent,
    StringTrimPipe,
    Nl2brPipe,
    MainToolbarComponent,
    PalmProgressComponent,
    SearchResultComponent,
    AvatarComponent,
    ScoreBadgeComponent,
    ExpandableItemComponent,
    PopoverMenuComponent,
    PopoverMenuComponent,
    AttentionMarkerComponent,
    VideoThumbnailComponent,
    QuestionComponent,
    DotComponent,
    LoadingPanelComponent,
    FetchErrorPanelComponent,
    PluralPipe,
    NiceDatePipe,
    MatchMarkerPipe,
    StripTagsPipe,
    TrimAroundPipe,
    MainPanelImageComponent,
    PromoModalComponent,
    CourseCertificateCardComponent,
    CourseCertificateModalComponent,
    CourseCertificatePaymentProgressComponent,
    ShareModalComponent,
    DragToScrollDirective,
    ScrollShadowDirective,
    CourseCardComponent,
    SkeletonLoadingDirective,
    SkeletonLoadingComponent,
    AboutTeacherModalComponent,
    SafeHtmlPipe,
    BottomBarComponent,
    ObservableContextDirective,
    ShufflePipe,
    BaseInputComponent,
    TextFieldComponent,
    FieldErrorMessageComponent,
    SettingsModalComponent,
    CarouselComponent,
    CoursesRecommendationCardComponent,
    ToArrayPipe,
    CoursePurchasingModalComponent,
    SearchModalComponent,
    AutoFocus,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CardComponent,
    StringTrimPipe,
    Nl2brPipe,
    MainToolbarComponent,
    PalmProgressComponent,
    AvatarComponent,
    ScoreBadgeComponent,
    ExpandableItemComponent,
    PopoverMenuComponent,
    PopoverMenuComponent,
    AttentionMarkerComponent,
    VideoThumbnailComponent,
    QuestionComponent,
    DotComponent,
    LoadingPanelComponent,
    FetchErrorPanelComponent,
    PluralPipe,
    NiceDatePipe,
    MainPanelImageComponent,
    PromoModalComponent,
    CourseCertificateCardComponent,
    CourseCertificateModalComponent,
    CourseCertificatePaymentProgressComponent,
    ShareModalComponent,
    DragToScrollDirective,
    ScrollShadowDirective,
    CourseCardComponent,
    SkeletonLoadingDirective,
    SkeletonLoadingComponent,
    AboutTeacherModalComponent,
    SafeHtmlPipe,
    BottomBarComponent,
    ObservableContextDirective,
    ShufflePipe,
    BaseInputComponent,
    TextFieldComponent,
    FieldErrorMessageComponent,
    SettingsModalComponent,
    CarouselComponent,
    CoursesRecommendationCardComponent,
    ToArrayPipe,
    CoursePurchasingModalComponent,
    SearchModalComponent,
    AutoFocus,
  ]
})
export class SharedModule { }
