import { AfterViewInit, Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { CourseCertificateModalComponent } from '../course-certificate-modal/course-certificate-modal.component';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  CoursesCertificatesHttpRequestsService
} from '../../services/http-requests/courses-certificates-http-requests.service';

@Component({
  selector: 'app-course-certificate-card',
  templateUrl: './course-certificate-card.component.html',
  styleUrls: ['./course-certificate-card.component.scss'],
})
export class CourseCertificateCardComponent implements AfterViewInit {

  @Input() set categoryId(value: string) {
    this.certificatePreviewUrl = `${environment.backendUrl}/files/coursePreviewCertificates/${value}.jpg`;
    this.subjectId = value;
    this.getSubjectInfo(value).subscribe(response => {
      this.subjectId = response.id;
      this.subjectLevel = response.level;
      this.subjectTitle = response.title;
      this.isAlreadyPaid = +response.paid === 1;
      this.certificatePreviewUrl = response?.certificate_preview_url;
    });
  }

  subjectId = null;
  subjectLevel = null;
  subjectTitle = null;
  isAlreadyPaid = false;

  certificatePreviewUrl = `${environment.backendUrl}/images/course_certificate_images/certificate_preview.jpg`;

  showModalSubscription: Subscription;

  isModalOnceWasOpen = false;

  constructor(private coursesCertificatesHttpRequestsService: CoursesCertificatesHttpRequestsService,
              private _modalController: ModalController) {
  }

  ngAfterViewInit() {
    this.showModalSubscription = of([1]).pipe(delay(3000)).subscribe(() => {
      if (!this.isModalOnceWasOpen) {
        this.openModal();
      }
      this.showModalSubscription.unsubscribe();
    });
  }

  getSubjectInfo(subjectId: string) {
    return this.coursesCertificatesHttpRequestsService.getCourseCertificateInfo(subjectId);
  }

  async openModal() {
    if (this.isAlreadyPaid) {
      return;
    }

    const modal = await this._modalController.create({
      component: CourseCertificateModalComponent,
      componentProps: {
        categoryId: this.subjectId,
      },
      cssClass: 'modal_80x50'
    });

    this.isModalOnceWasOpen = true;

    return await modal.present();
  }

  closeModal() {
    this._modalController.dismiss();
  }

}
