<ion-item
class="question-item"
lines="none"
[button]="expandable"
[detail]="expandable"
detailIcon="chevron-down-outline"
[class.expanded]="expanded"
(click)="expandable?toggle():null">
  <app-avatar slot="start" [image]="question.author.avatarUrl"></app-avatar>
  <div class="question-body">
    <div *ngIf="showLesson && question.for && question.for.title" class="question-for" [innerHtml]="question.for.title"></div>
    <div *ngIf="!audio" class="question-text" [innerHtml]="question.text | nl2br"></div>
    <audio *ngIf="audio" [src]="question.audio" controls></audio>
    <div class="question-footer">
      <span class="question-name">{{question.author.name}}</span>
      <span *ngIf="question.hasUnviewedChildren" class="question-unviewed text-color-danger">Новый ответ</span>
      <!-- <span class="question-date">{{question.date}}</span> -->
    </div>
  </div>
</ion-item>

<ion-list
*ngIf="question.children && question.children.length>0 && expanded"
lines="none"
[@expand]>
  <app-question
  *ngFor="let child of question.children"
  [question]="child"
  ></app-question>
</ion-list>
