<div class="mainContainer">

  <h2>Настройки плеера:</h2>

  <div class="playerSettings">
    <div *ngFor="let playerType of ($playerTypes | async)"
          [class.activeButton]="($currentPlayerType | async) === playerType.value"
          [class.disabledButton]="playerType.disabled"
         (click)="selectPlayer(playerType.value)">
      <ng-template #uncheckedPlayerTypeTemplate>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="2.39062" y="2.5" width="20" height="20" rx="5" stroke="#A7A7A7" stroke-width="1.5"/>
        </svg>
      </ng-template>
      <ng-container *ngIf="($currentPlayerType | async) === playerType.value; else uncheckedPlayerTypeTemplate">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="2.39062" y="2.5" width="20" height="20" rx="5" stroke="#3FB49A" stroke-width="1.5"/>
          <path d="M9.89063 12L11.8906 14L15.8906 10" stroke="#3FB49A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </ng-container>
      <span>{{ playerType.label }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <div class="actionButtons">
    <div (click)="navigateToCoursePage()">
      <ng-container>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.5 6C3.5 3.79086 5.29086 2 7.5 2H12.5H15.0085C15.5614 2 16.0895 2.22882 16.4676 2.63212L20.9591 7.42301C21.3066 7.79371 21.5 8.28278 21.5 8.79089V12V18C21.5 20.2091 19.7091 22 17.5 22H7.5C5.29086 22 3.5 20.2091 3.5 18V6Z"
            stroke="#A7A7A7" stroke-width="1.5"/>
          <path d="M15.3901 2.5V6C15.3901 7.10457 16.2856 8 17.3901 8H21.0573" stroke="#A7A7A7" stroke-width="1.5"
                stroke-linecap="round"/>
          <path d="M7.10059 12H16.1568" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M7.10059 17H11.6287" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </ng-container>
      <span>О курсе</span>
    </div>
    <div (click)="openShareModal()">
      <ng-container>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.5 11L11.7929 8.70711C12.1834 8.31658 12.8166 8.31658 13.2071 8.70711L15.5 11M12.5 9V16M22.5 7V17C22.5 19.7614 20.2614 22 17.5 22H7.5C4.73858 22 2.5 19.7614 2.5 17L2.5 7C2.5 4.23858 4.73858 2 7.5 2L17.5 2C20.2614 2 22.5 4.23858 22.5 7Z"
            stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </ng-container>
      <span>Подделиться курсом</span>
    </div>
  </div>


</div>
