import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { OrientationType, ScreenOrientation } from '@capawesome/capacitor-screen-orientation';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService{

  isFullscreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isFullscreen.subscribe(isFullscreen=>{
      if(Capacitor.isPluginAvailable('StatusBar')){
        isFullscreen ? StatusBar.hide() : StatusBar.show();
      }

      if(Capacitor.isPluginAvailable('ScreenOrientation')){
        if(isFullscreen){
          ScreenOrientation.getCurrentOrientation().then(result=>{
            const orientationType = result.type == OrientationType.LANDSCAPE_PRIMARY || result.type == OrientationType.LANDSCAPE_SECONDARY
              ? result.type
              : OrientationType.LANDSCAPE;
            return ScreenOrientation.lock({ type: orientationType });
          }).catch(()=>{});
        }else{
          ScreenOrientation.lock({ type: OrientationType.PORTRAIT }).catch(()=>{});
          // ScreenOrientation.unlock().catch(()=>{});
        }
      }

      if(Capacitor.getPlatform()==='android' && Capacitor.isPluginAvailable('NavigationBar')){
        isFullscreen ? NavigationBar.hide() : NavigationBar.show();
      }

      if(Capacitor.isPluginAvailable('KeepAwake')){
        isFullscreen ? KeepAwake.keepAwake() : KeepAwake.allowSleep();
      }

    });
  }

}
