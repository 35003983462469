
<ion-grid>
<ion-grid>
  <ion-row>
    <div class="courseCertificateCard" (click)="openModal()">

      <div class="top-speech">
        <ion-text>Помогите нам оставаться бесплатными</ion-text>
      </div>

      <div class="courseCertificateCardContent">
        <ion-img class="certificatePreviewImg" [src]="certificatePreviewUrl"></ion-img>


        <ion-text class="card-text">
          Сертификат прохождения {{subjectLevel}}-го уровня по предмету {{subjectTitle}}
        </ion-text>


        <ion-button color="primary" [fill]="'solid'">
          Приобрести
        </ion-button>
      </div>
    </div>
  </ion-row>

</ion-grid>
</ion-grid>

