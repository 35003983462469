<div class="aboutTeacherModalMainContainer">

  <section class="modalHeaderContainer" >
    <div class="modalHeader">
      <ion-icon name="close-outline" [style.visibility]="'hidden'"></ion-icon>
      <span class="modalTitle">Преподаватель</span>
      <ion-icon (click)="closeModal()" name="close-outline"></ion-icon>
    </div>
  </section>

  <div class="modalContent" scrollShadow [top]="true" [bottom]="true">

    <div class="aboutTeacherHeader">

      <div class="teacherImgAvatar">
        <img src="{{teacherAvatar}}">
      </div>

      <span class="teacherName">{{teacherName}}</span>
      <div class="teacherShortDescription" [innerHTML]="teacherShortDescription | safeHtml"></div>



    </div>

    <div class="divider"></div>

    <div class="teacherDescription" [innerHTML]="teacherDescription | safeHtml"></div>

  </div>
</div>
