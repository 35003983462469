import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {


  transform(value: number, wordForms: [string,string,string]): string {

    const cases = [2,0,1,1,1,2];
    return value.toString() + ' ' + wordForms[(value%100>4 && value%100<20)?2:cases[Math.min(value%10, 5)]];
  }

}
