import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics.service';
import { ToastService } from '../../services/toast.service';
import { SearchType } from '../search-type.model';
import { SearchResultAnimations } from './search-result.animations';
import { SearchResult } from './search-result.model';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: SearchResultAnimations
})
export class SearchResultComponent implements OnInit {

  @Input('searchResult') result: SearchResult;
  @Input('searchType') searchType: SearchType;
  @Input('searchQuery') searchQuery: string;
  @Output('onNavigate') onNavigate: EventEmitter<void> = new EventEmitter<void>();

  isShaking: boolean = false;

  constructor(
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private _toastService: ToastService
  ) { }

  ngOnInit() {}

  onClick(){
    if(this.result.available){
      this.onNavigate.emit();
      this._firebaseAnalyticsService.logEvent('select_content',{
        item_id: this.result.id,
        content_type: this.searchType,
        search_term: this.searchQuery
      });
      return;
    }
    this._firebaseAnalyticsService.logEvent('search_unavailableClick',{
      itemId: this.result.id,
      itemType: this.searchType,
      search_term: this.searchQuery
    });
    this._toastService.show({
      message: 'Предметы этого уровня Вам еще недоступны!',
      color: 'warning',
      duration: 2000
    })
    if(Capacitor.isPluginAvailable('Haptics')){
      Haptics.notification({type: NotificationType.Warning});
    }
    this.isShaking = true;
  }

  onShakeFinished(){
    this.isShaking = false;
  }

}
