import { animate, group, query, state, style, transition, trigger, useAnimation } from "@angular/animations";
import { shake } from "ng-animate";

export const SearchResultAnimations = [
  trigger('shake', [transition('* => true', useAnimation(shake, {
    params: {
      timing: 0.2
    }
  }))])
];
