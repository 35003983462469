import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoursesCertificatesHttpRequestsService {

  baseUrl = environment.api4Url + '/courses_certificates';

  constructor(private httpClient: HttpClient) { }

  getPurchasedAndAvailableToBuy(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/getPurchasedAndAvailableToBuy`,
      {
        observe: 'response',
      }
    ).pipe(
      map(responseBody => responseBody.body),
      retry(0)
    );
  }

  getSubjectInfo(subjectId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/getSubjectInfo?subjectId=${subjectId}`,
      {
        observe: 'response',
      }
    ).pipe(
      map(responseBody => responseBody.body),
      retry(0)
    );
  }

  getUserLastCourseCertificateInfo(): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/getUserLastCourseCertificateInfo`,
      {
        observe: 'response',
      }
    ).pipe(
      map(responseBody => responseBody.body),
      retry(0)
    );
  }

  createCertificate(nameI: string, nameF: string, nameO: string, subjectId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/createCertificate?name_i=${nameI}&name_o=${nameO}&name_f=${nameF}&categoryId=${subjectId}`,
      null,
      {
        observe: 'response',
      }
    ).pipe(
      map(responseBody => responseBody.body),
      retry(0)
    );
  }

  getCourseCertificateInfo(certificateId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/getCourseCertificateInfo?certificateId=${certificateId}`,
      {
        observe: 'response',
      }
    ).pipe(
      map(responseBody => responseBody.body),
      retry(0)
    );
  }

  getCourseCertificatesPrices(): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/getCourseCertificatesPrices`,
      {
        observe: 'response',
      }
    ).pipe(
      map(responseBody => responseBody.body),
      retry(0)
    );
  }

  getCourseCertificateInfoByCategoryId(categoryId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/getCourseCertificateInfoByCategoryId?categoryId=${categoryId}`,
      {
        observe: 'response',
      }
    ).pipe(
      map(responseBody => responseBody.body),
      retry(0)
    );
  }


}
