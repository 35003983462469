import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CoursesRecommendationsModel } from '../Models/courses-models/courses-recommendations.model';

@Component({
  selector: 'app-courses-recommendation-card',
  templateUrl: './courses-recommendation-card.component.html',
  styleUrls: ['./courses-recommendation-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursesRecommendationCardComponent {

  @Input() coursesRecommendation: CoursesRecommendationsModel;
  @Input() isActive: boolean;

  constructor() { }

}
