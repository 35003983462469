<ion-header [class.hidden]="isFullscreen$ | async" [class.header-searchbar]="isSearch">
  <ion-toolbar>
    <!-- <ion-input *ngIf="isSearch" placeholder="Поиск" type="search"></ion-input> -->
    <ng-container *ngIf="isAllowSearch">
      <form (ngSubmit)="onSearchSubmit()" #searchForm="ngForm">
        <ion-searchbar
          [class.hidden]="!isSearch"
          placeholder="поиск"
          enterkeyhint="search"
          (keyup.enter)="searchForm.ngSubmit.emit()"
          [disabled]="isSearchLoading"
          #searchInput
        ></ion-searchbar>
      </form>
      <ion-buttons [class.hidden]="!isSearch" slot="end">
        <ion-button type="flat" (click)="cancelSearch()">отмена</ion-button>
      </ion-buttons>
    </ng-container>

    <ion-buttons slot="start" [class.hidden]="isSearch">
      <ion-menu-button *ngIf="mainButton == 'menu'">
        <ion-icon name="menu-outline"></ion-icon>
        <app-attention-marker *ngIf="menuNeedAttention"></app-attention-marker>
      </ion-menu-button>
      <ion-back-button *ngIf="mainButton == 'back'" defaultHref="/home" icon="chevron-back-outline"></ion-back-button>
<!--      <ion-button *ngIf="isAllowSearch" (click)="toggleSearch()">-->
<!--        <ion-icon name="search-outline" slot="icon-only"></ion-icon>-->
<!--      </ion-button>-->
    </ion-buttons>

    <ion-title *ngIf="!isPalmVisible && title && !isSearch"
               [routerLink]="titleLink ? ['/course', titleLink] : null">
      {{title}}
    </ion-title>

    <div
    *ngIf="isAuthenticated"
    slot="end"
    class="ion-padding-end avatar-wrapper"
    [class.hidden]="isSearch"
    [routerLink]="['/account']">
      <app-avatar [isLoading]="!myAvatar" [image]="myAvatar"></app-avatar>
      <!-- <app-score-badge></app-score-badge> -->
      <app-attention-marker *ngIf="accountNeedAttention"></app-attention-marker>
    </div>
  </ion-toolbar>
</ion-header>

<div
  *ngIf="isAuthenticated && mainPanelImage"
  [class.hidden]="!isPalmVisible || (isFullscreen$ | async) || isSearch"
  class="palm-progress-background"
  [style]="{ '--size': palmSize + 'px', '--stroke': palmStroke + 'px' }"
  [@palmBgInOut]="isPalmVisible && !isSearch"
></div>

<app-main-toolbar-image
  class="progress-image"
  *ngIf="isAuthenticated && mainPanelImage"
  [image]="mainPanelImage"
  [class.hidden]="!isPalmVisible || (isFullscreen$ | async) || isSearch"
  [size]="palmSize"
  [stroke]="palmStroke"
  [style]="{ '--size': palmSize + 'px', '--stroke': palmStroke + 'px' }"
  [@palmInOut]="isPalmVisible && !isSearch"
></app-main-toolbar-image>

<!-- <app-palm-progress
class="progress-image"
*ngIf="isAuthenticated"
[class.hidden]="!isPalmVisible || isFullscreen || isSearch"
[size]="palmSize"
[stroke]="palmStroke"
[style]="{'--size':palmSize+'px','--stroke':palmStroke+'px'"
[routerLink]="['/account/garden']"
[@palmInOut]="isPalmVisible && !isSearch"></app-palm-progress> -->

<ion-content *ngIf="isSearch" class="search-results">
  <ion-segment (ionChange)="segmentChanged($event)" [value]="searchType">
    <ion-segment-button value="lessons">
      <ion-label>Уроки</ion-label>
    </ion-segment-button>
    <ion-segment-button value="webinars">
      <ion-label>Вебинары</ion-label>
    </ion-segment-button>
    <ion-segment-button value="news">
      <ion-label>Новости</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ion-grid class="maingrid ion-no-padding">
    <ion-row class="ion-justify-content-center">
      <ion-col class="maincol"
      size="12"
      sizeSm="8"
      sizeMd="7"
      sizeLg="6"
      sizeXl="5">

        <app-search-result
        *ngFor="let result of searchResults; let resultIndex = index"
        [searchResult]="result"
        [searchType]="searchType"
        [searchQuery]="searchQuery"
        (onNavigate)="onSearchResultNavigated(resultIndex)"
        [id]="'search-result-' + resultIndex"
        ></app-search-result>

        <ion-infinite-scroll
          (ionInfinite)="onInfinite($event)"
          [disabled]="
            scrollReachedTheEnd || !searchResults || searchResults.length < 1
          "
        >
          <ion-infinite-scroll-content
            loadingSpinner="dots"
            color="primary"
          ></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="search-loading" *ngIf="isSearchLoading">
    <ion-spinner name="dots" color="primary"></ion-spinner>
  </div>
  <div class="search-error" *ngIf="!isSearchLoading && isSearchError">
    <h1>Произошла ошибка</h1>
    <p class="ion-padding-vertical">
      Проверьте соединение и попробуйте еще раз
    </p>
    <ion-button color="white" (click)="search().subscribe()"
      >Повторить</ion-button
    >
  </div>
  <div
    class="search-notFound"
    *ngIf="
      !isSearchLoading &&
      !isSearchError &&
      searchResults &&
      searchResults.length < 1
    "
  >
    <h1>Ничего не найдено</h1>
    <p>Попробуте перефразировать Ваш запрос</p>
  </div>

  <div class="search-short" *ngIf="isSearchShort">
    <h1>Введите запрос</h1>
    <p>Пожалуйста, введите не менее 3-х букв для начала поиска.</p>
  </div>
</ion-content>
