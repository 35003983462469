import { Injectable } from '@angular/core';
import { finalize, map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CoursesHttpRequestsService } from './http-requests/courses-http-requests.service';
import { CourseCard } from '../Models/courses-models/course-card.model';
import { CourseModel } from '../Models/courses-models/course.model';
import { CourseProgressStatusEnum } from '../Models/courses-models/course-progress-status.enum';
import { CoursesCategoryModel } from '../Models/courses-models/courses-category.model';
import { CoursesRecommendationsModel } from '../Models/courses-models/courses-recommendations.model';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  isStartedCoursesLoading$ = new BehaviorSubject(true);
  private startedCoursesSubject$ = new BehaviorSubject<CourseCard[]>([]);
  startedCourses$ = this.startedCoursesSubject$.asObservable();

  isNotStartedCoursesLoading$ = new BehaviorSubject(true);
  private notStartedCoursesSubject$ = new BehaviorSubject<CourseCard[]>([]);
  notStartedCourses$ = this.notStartedCoursesSubject$.asObservable();

  isFinishedCoursesLoading$ = new BehaviorSubject(true);
  private finishedCoursesSubject$ = new BehaviorSubject<CourseCard[]>([]);
  finishedCourses$ = this.finishedCoursesSubject$.asObservable();

  isAllCoursesLoading$ = new BehaviorSubject(true);
  private allCoursesSubject$ = new BehaviorSubject<CourseCard[]>([]);
  allCourses$ = this.allCoursesSubject$.asObservable();

  isCoursesCategoriesLoading$ = new BehaviorSubject(true);
  private coursesCategoriesSubject$ = new BehaviorSubject<CoursesCategoryModel[]>([]);
  coursesCategories$ = this.coursesCategoriesSubject$.asObservable();

  isCoursesRecommendationsLoading$ = new BehaviorSubject(true);
  private coursesRecommendationsSubject$ = new BehaviorSubject<CoursesRecommendationsModel[]>([]);
  coursesRecommendations$ = this.coursesRecommendationsSubject$.asObservable();

  isNewCoursesIdsLoading$ = new BehaviorSubject(true);
  private newCoursesIdsSubject$ = new BehaviorSubject<number[]>([]);
  newCoursesIds$ = this.newCoursesIdsSubject$.asObservable();

  constructor(private coursesHttpService: CoursesHttpRequestsService) {
  }

  getAllHomePageCourses() {
    this.getStartedCourses().subscribe(response => {
      this.startedCoursesSubject$.next(response);
    });
    this.getNotStartedCourses().subscribe(response => {
      this.notStartedCoursesSubject$.next(response);
    });
    this.getFinishedCourses().subscribe(response => {
      this.finishedCoursesSubject$.next(response);
    });
  }

  getAllCoursesAndCoursesCategories() {

    this.getAllCoursesRecommendations().subscribe(response => {
      this.coursesRecommendationsSubject$.next(response);
    });

    this.getNewCoursesIds().subscribe(response => {
      this.newCoursesIdsSubject$.next(response);
    });

    this.getAllCoursesCategories().subscribe(response => {
      this.coursesCategoriesSubject$.next(response);
    });

    this.getAllCourses().subscribe(response => {
      this.allCoursesSubject$.next(response);
    });

  }

  getCourseInfoById(id: number) {
    return this.coursesHttpService.getSchoolCourseById(id).pipe(
      map(response => response.body),
      map(response => {

        const course = response;
        course.promo_video_id = course.promo_video_id.replace('youtube:', '');

        course.currentLevelIndex = 0;

        course.next_lesson_id = course.levels.find((l, index) => {
          course.currentLevelIndex = index;
          return !l.exam_result_id;
        })?.next_lesson_id;

        if (course.currentLevelIndex === course.levels.length - 1) {
          course.levels[0].isOpen = true;
        } else {
          course.levels[course.currentLevelIndex].isOpen = true;
        }

        if (!course.levels[course.currentLevelIndex].next_lesson_id) {
          course.next_lesson_id = course.levels[course.currentLevelIndex].lessons[0].id;
        }

        course.allLessonsCount = course.levels.reduce((count, current) => count + current.lessons.length, 0);

        course.progressStatus = this.calculateProgressStatus(course);

        course.levels.map((level, levelIndex) => {
          level.lessons.map((lesson, lessonIndex) => {
            if (course.isPaid) {

              if (course.isPurchased) {
                lesson.isAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
              } else if (levelIndex === 0) {
                lesson.isAllowed = lessonIndex < course.trial_lessons_quantity;
              }

            } else {
              lesson.isAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
            }
            return lesson;
          });

          if (course.isPaid) {

            if (course.isPurchased) {
              level.isExamAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
            } else {
              level.isExamAllowed = false;
            }

          } else {
            level.isExamAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
          }

          return level;
        });

        return course;
      }),
    );
  }

  getCourseInfoHttp(url: string) {
    return this.coursesHttpService.getSchoolCourseByUrl(url).pipe(
      map(response => response.body),
      map(response => {

        const course = response;
        course.promo_video_id = course.promo_video_id.replace('youtube:', '');

        course.currentLevelIndex = 0;

        course.next_lesson_id = course.levels.find((l, index) => {
          course.currentLevelIndex = index;
          return !l.exam_result_id;
        })?.next_lesson_id;

        if (course.currentLevelIndex === course.levels.length - 1) {
          course.levels[0].isOpen = true;
        } else {
          course.levels[course.currentLevelIndex].isOpen = true;
        }

        if (!course.levels[course.currentLevelIndex].next_lesson_id) {
          course.next_lesson_id = course.levels[course.currentLevelIndex].lessons[0].id;
        }

        course.allLessonsCount = course.levels.reduce((count, current) => count + current.lessons.length, 0);

        course.progressStatus = this.calculateProgressStatus(course);

        course.levels.map((level, levelIndex) => {
          level.lessons.map((lesson, lessonIndex) => {
            if (course.isPaid) {

              if (course.isPurchased) {
                lesson.isAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
              } else if (levelIndex === 0) {
                lesson.isAllowed = lessonIndex < course.trial_lessons_quantity;
              }

            } else {
              lesson.isAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
            }
            return lesson;
          });

          if (course.isPaid) {

            if (course.isPurchased) {
              level.isExamAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
            } else {
              level.isExamAllowed = false;
            }

          } else {
            level.isExamAllowed = (!!course?.levels[levelIndex - 1]?.exam_result_id) || !course?.levels[levelIndex - 1];
          }

          return level;
        });
        return course;
      }),
    );
  }

  private calculateProgressStatus(course: CourseModel): CourseProgressStatusEnum {
    if (course.next_lesson_id !== course.levels[0].lessons[0].id) {
      if (course.levels.find(level => !level.exam_result_id)) {
        return CourseProgressStatusEnum.StartedNotFinished;
      } else {
        return CourseProgressStatusEnum.Finished;
      }
    } else {
      return CourseProgressStatusEnum.NotStarted;
    }
  }

  private getStartedCourses() {
    return this.coursesHttpService.getStartedSchoolCoursesForCurrentUser().pipe(
      tap(() => {
        this.isStartedCoursesLoading$.next(true);
      }),
      map(response => {
        return response.body.map(course => {
          course.progressPercents = Math.round(course.viewed_count * 100 / course.max_lessons_number);
          course.progressPercents = course.progressPercents === 100 ? 99 : course.progressPercents;
          return course;
        });
      }),
      finalize(() => {
        this.isStartedCoursesLoading$.next(false);
      }),
    );
  }

  private getNotStartedCourses() {
    return this.coursesHttpService.getNotStartedSchoolCoursesForCurrentUser().pipe(
      tap(() => this.isNotStartedCoursesLoading$.next(true)),
      map(response => {
        return response.body;
      }),
      finalize(() => this.isNotStartedCoursesLoading$.next(false)),
    );
  }

  private getFinishedCourses() {
    return this.coursesHttpService.getFinishedSchoolCoursesForCurrentUser().pipe(
      tap(() => this.isFinishedCoursesLoading$.next(true)),
      map(response => {
        return response.body.map(course => {
          course.progressPercents = 100;
          return course;
        });
      }),
      finalize(() => this.isFinishedCoursesLoading$.next(false)),
    );
  }

  private getAllCourses() {
    return this.coursesHttpService.getAllCourses().pipe(
      tap(() => this.isAllCoursesLoading$.next(true)),
      map(response => {
        return response.body.map(course => {
          course.course_progress_status = CourseProgressStatusEnum.NotStarted;
          return course;
        });
      }),
      finalize(() => this.isAllCoursesLoading$.next(false)),
    );
  }

  private getAllCoursesCategories() {
    return this.coursesHttpService.getAllCoursesCategories().pipe(
      tap(() => this.isCoursesCategoriesLoading$.next(true)),
      map(response => {
        return response.body.map(coursesCategory => {
          return coursesCategory;
        });
      }),
      finalize(() => this.isCoursesCategoriesLoading$.next(false)),
    );
  }

  private getAllCoursesRecommendations() {
    return this.coursesHttpService.getAllCoursesRecommendations().pipe(
      tap(() => this.isCoursesRecommendationsLoading$.next(true)),
      map(response => {
        return response.body.map(coursesRecommendation => {
          return coursesRecommendation;
        });
      }),
      finalize(() => this.isCoursesRecommendationsLoading$.next(false)),
    );
  }

  private getNewCoursesIds() {
    return this.coursesHttpService.getNewCoursesIds().pipe(
      tap(() => this.isNewCoursesIdsLoading$.next(true)),
      map(response => {
        return response.body.map(ids => {
          return ids;
        });
      }),
      finalize(() => this.isNewCoursesIdsLoading$.next(false)),
    );
  }

}
