import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { ShareModalComponent } from '../../share-modal/share-modal.component';
import {
  CoursesCertificatesHttpRequestsService
} from '../../services/http-requests/courses-certificates-http-requests.service';

@Component({
  selector: 'app-course-certificate-modal',
  templateUrl: './course-certificate-modal.component.html',
  styleUrls: ['./course-certificate-modal.component.scss'],
})
export class CourseCertificateModalComponent {

  @Input() set categoryId(value: string) {
    this.getPassedCategories().subscribe(response => {
      const category = response.find(x => x.subject.toString() === value);
      if (!!category) {
        // console.log(category);

        const isOrdered = !!category.courseCertificateId;
        const isPaid = category.paid === '1';

        // if (isOrdered) {
        //   this.certificateGetUrl = isPaid
        //     ? '/course-certificate/' + category.courseCertificateId
        //     : '/course-certificate-purchase/' + category.courseCertificateId;
        // } else {
        //   this.certificateGetUrl = '/course-certificate-order/' + category.level + '/' + category.subject;
        // }

        this.certificateGetUrl = isPaid
          ? '/course-certificate/' + category.courseCertificateId
          : '/course-certificate-order/' + category.level + '/' + category.subject;
      }
    });
    this.certificatePreviewUrl = `${environment.backendUrl}/files/coursePreviewCertificates/${value}.jpg`;
  }

  certificatePreviewUrl = `${environment.backendUrl}/images/course_certificate_images/certificate_preview.jpg`;

  certificateGetUrl = null;

  constructor(private _modalController: ModalController,
              private coursesCertificatesHttpRequestsService: CoursesCertificatesHttpRequestsService) {
  }


  closeModal() {
    this._modalController.dismiss();
  }

  async openShareModal() {
    this.closeModal();
    const modal = await this._modalController.create({
      component: ShareModalComponent,
      cssClass: 'modal_share'
    });

    return await modal.present();
  }

  getPassedCategories() {
    return this.coursesCertificatesHttpRequestsService.getPurchasedAndAvailableToBuy();
  }

}
