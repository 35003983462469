import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[observableContext]'
})
export class ObservableContextDirective<T> {
  private context: any = {};

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<{$implicit: T}>
  ) {}

  @Input()
  set observableContext(source: T) {
    // console.log(source);
    this.context.data = source;
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, {$implicit: source});
  }
}
