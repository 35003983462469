import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-fetch-error-panel',
  templateUrl: './fetch-error-panel.component.html',
  styleUrls: ['./fetch-error-panel.component.scss'],
})
export class FetchErrorPanelComponent implements OnInit, OnDestroy {

  @Output() onRetry = new EventEmitter<any>();
  private _networkListenerHandle: PluginListenerHandle;

  constructor(
    private _ngZone: NgZone
  ) { }

  ngOnInit() {
    Network.addListener('networkStatusChange', status => {
      if(!status.connected){
        return;
      }
      this._ngZone.run(()=>{
        this.retry();
      });
    }).then(handle=>{
      this._networkListenerHandle = handle;
    })
  }

  ngOnDestroy() {
    if(this._networkListenerHandle){
      this._networkListenerHandle.remove();
    }
  }

  retry(){
    this.onRetry.emit();
  }

}
