import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MainPanelImage } from '../../main-panel-image.model';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics.service';

@Component({
  selector: 'app-main-toolbar-image',
  templateUrl: './main-panel-image.component.html',
  styleUrls: ['./main-panel-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPanelImageComponent implements OnInit, OnDestroy, OnChanges {

  @Input('size') size = 100;
  @Input('stroke') stroke = 4;
  @Input('disabled') disabled: boolean = false;
  radius: number;
  circumference: number;
  strokeDashoffset: number;
  private _progress: number = 0;
  get imageSrc(): SafeUrl{
    return this.mainPanelImage?.imageUrl
      ? this._domSanitizer.bypassSecurityTrustUrl(this.mainPanelImage.imageUrl)
      : null;
  }
  imgStyle: {[key: string]: any};
  @Input('image') mainPanelImage: MainPanelImage = null;
  private _palmSubscribtion: Subscription;

  set progress(value: number){
    this._progress = value;
    this.strokeDashoffset = this.circumference - value / 100 * this.circumference;
  }

  get progress():number{
    return this._progress;
  }

  get filledColor(): string{
    return this.mainPanelImage.filledColor ?? 'var(--ion-color-warning)';
  }

  get unfilledColor(): string{
    return this.mainPanelImage.unfilledColor ?? 'var(--ion-color-light)';
  }

  constructor(
    private _domSanitizer: DomSanitizer,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private _navController: NavController
  ) { }

  ngOnInit() { }

  ngOnChanges(){
    const imgSize = this.size - this.stroke*2;
    this.imgStyle = {
      'left.px':this.stroke,
      'top.px':this.stroke,
      'width.px':imgSize,
      'height.px':imgSize,
    };

    this.radius = this.size/2 - this.stroke/2;
    this.circumference = this.radius * 2 * Math.PI;
    this.strokeDashoffset = this.circumference;
    if(this.mainPanelImage){
      this._updateProgress();
    }
  }

  ngOnDestroy(){
    if(this._palmSubscribtion){
      this._palmSubscribtion.unsubscribe();
    }
  }

  private _updateProgress(){
    if(!this.mainPanelImage?.progress){
      this.progress = 0;
      return;
    }
    this.progress = this.mainPanelImage.progress;
  }

  setRandomProgress(){
    const progress = this.getRandomInt(0,101);
    this.progress = progress;
  }

  getRandomInt(min, max):number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
  }

  onClick(){
    this._firebaseAnalyticsService.logEvent('customMainPanelImage_click');
    if(this.mainPanelImage?.externalLink){
      window.open(this.mainPanelImage.externalLink, '_system');
      return;
    }
    if(this.mainPanelImage?.routerLink){
      this._navController.navigateForward(this.mainPanelImage.routerLink);
      return;
    }
  }

}
