import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'niceDate'
})
export class NiceDatePipe implements PipeTransform {

  transform(unixtimestamp: number, alwaysShowTime?:boolean): string {
    return niceDate(unixtimestamp, {
      alwaysShowTime: alwaysShowTime??false
    });
  }

}

export function niceDate(timestamp: number, options?: {alwaysShowTime?:boolean, showWeekDay?:boolean}): string{
  const date = new Date(timestamp*1000);
  const now = new Date();
  const alwaysShowTime = options && options.alwaysShowTime;
  const showWeekDay = options && options.showWeekDay;
  const formatter = new Intl.DateTimeFormat('default', {
    day: 'numeric',
    month: 'short',
    year: date.getFullYear()!=now.getFullYear() ? 'numeric' : undefined,
    // year: 'numeric',
    weekday: showWeekDay ? 'long' : undefined,
    hour: alwaysShowTime || date > now ? 'numeric' : undefined,
    minute: alwaysShowTime || date > now ? 'numeric' : undefined
  });

  return formatter.format(date);
}
