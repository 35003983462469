<ion-card
(click)="onClick()"
[routerLink]="card.routerLink"
[style]="cardStyle"
class="{{card.style}} {{card.imageStyle}}"
[class.with-color]="!!card.color"
>
  <ion-badge
    *ngIf="card.attentionBadge"
    [innerHtml]="card.attentionBadge"
    class="attention-badge"></ion-badge>
  <ion-img *ngIf="card.imageStyle=='imageOnly'" [src]="card.card_image ? card.card_image : card.image"></ion-img>
  <ion-card-content *ngIf="card.imageStyle!='imageOnly'">
    <div class="badge">
      <ion-badge
      class="ion-text-uppercase {{card.badgeStyle}} card-badge"
      *ngIf="card.badge"
      [ngStyle]="{'background-color':card.color}"
      [innerHtml]="card.badge"></ion-badge>
      <ion-icon
      *ngIf="card.badgeStar"
      color="warning"
      src="./assets/icons/star.svg"></ion-icon>
      <span class="badgeAdd" *ngIf="card.badgeAdd" [innerHtml]="card.badgeAdd"></span>
    </div><!--badge-->
    <div class="card-title" *ngIf="card.title" [innerHtml]="card.title"></div>
    <div class="card-text" *ngIf="card.text" [innerHtml]="card.text"></div>
    <div class="card-footer" *ngIf="card.duration">
      <ion-label class="card-time">
        <ion-icon slot="start" name="time-outline"></ion-icon>
        <span [innerHtml]="card.duration"></span>
      </ion-label>
    </div>
  </ion-card-content>
</ion-card>
