<ion-tab-bar [class.hidden]="(isVideoFullscreen$ | async)"
             slot="bottom"
             [selectedTab]="this.currentTab"
             #tabBar>

  <ion-tab-button
    *ngFor="let tab of tabsList"
    title="{{tab.title}}"
    tab="{{tab.name}}"
    [disabled]="tab.name === 'play' && !(isShowPlayButton$ | async)"
    (click)="onTabButtonClick(tab.name)">

    <ion-icon src="assets/icons/{{tabBar.selectedTab == tab.name ? (tab.name + '-active') : tab.name}}.svg">
    </ion-icon>

    <ion-label class="tabTitle"
               [class.active]="tabBar.selectedTab == tab.name">
      {{ tab.title }}
    </ion-label>

  </ion-tab-button>

</ion-tab-bar>
