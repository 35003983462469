import {
  AfterViewInit,
  Component,
  Host,
  Input, OnDestroy,
  Optional,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';
import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextFieldComponent,
    multi: true
  }]
})
export class TextFieldComponent extends BaseInputComponent implements AfterViewInit, OnDestroy {

  @ViewChild('textInput') textInput: IonInput;

  @Input() type: 'text' | 'password' = 'text';
  @Input() autoComplete: string;
  @Input() helperText: string;

  isShowPassword = false;

  isFocused = false;

  subscriptions: Subscription[] = [];

  constructor(
    @Optional() @Host() @SkipSelf()
    private _controlContainer: ControlContainer
  ) {
    super(_controlContainer);
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.textInput.ionFocus.subscribe(() => this.isFocused = true),
      this.textInput.ionBlur.subscribe(() => this.isFocused = false)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setFocus() {
    this.textInput.setFocus();
  }

  test() {
    console.log(this.formController);
  }

}
