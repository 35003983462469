import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AccountService } from 'src/app/account/account.service';
import { environment } from 'src/environments/environment';
import { ExpandAnimations } from '../expand.animations';
import { QuestionItem } from './question.model';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  animations: ExpandAnimations
})
export class QuestionComponent implements OnInit {

  audio: SafeUrl;

  constructor(
    private _httpClient: HttpClient,
    private _accountService: AccountService,
    private _domSanitizer: DomSanitizer
  ) { }

  @Input() question: QuestionItem;
  @Input() showLesson: boolean = false;

  @HostBinding('class.parent') isParent: boolean = false;
  @HostBinding('class.expanded') expanded: boolean = false;

  get expandable(){
    return this.question.children && this.question.children.length>0;
  }

  ngOnInit() {
    this.isParent = !this.question.parent || this.question.parent==0;
    if(this.question.audio){
      this.audio = this._domSanitizer.bypassSecurityTrustUrl(this.question.audio);
    }
  }

  toggle(){
    this.expanded = !this.expanded;

    if(this.expanded && this.question.hasUnviewedChildren){
      this.question.hasUnviewedChildren = false;
      this._httpClient.get<{result:string,unviewedAnswers?:number}>(`${environment.apiUrl}/setAnswersViewed?parentId=${this.question.id}`).subscribe(result=>{
        if(result?.hasOwnProperty('unviewedAnswers')){
          this._accountService.setUnviewedAnswers(result.unviewedAnswers);
        }
      });
    }
  }

}
