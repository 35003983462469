import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  @Input() isLoading: boolean = false;
  private _image: SafeUrl;
  get image(): SafeUrl{
    return this._image;
  }
  @Input('image') set imageUnsafeUrl(value: string){
    if(!value){
      this._image = null;
      return;
    }
    const newSafeUrl = this._domSanitizer.bypassSecurityTrustUrl(value);
    if(newSafeUrl == this.image){
      return;
    }
    this._image = newSafeUrl;
  };

  constructor(
    private _domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {}

}
