import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { CoursesService } from '../services/courses.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { CourseModel } from '../Models/courses-models/course.model';
import { AccountService } from '../../account/account.service';
import { delay, finalize, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-course-purchasing-modal',
  templateUrl: './course-purchasing-modal.component.html',
  styleUrls: ['./course-purchasing-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePurchasingModalComponent implements OnDestroy {
  @Input() set courseId(id: string) {
    this.courseId$.next(id);
  }

  courseId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get courseObservable$(): Observable<CourseModel> {
    return this.courseId$.pipe(
      switchMap(id =>
        this.coursesService.getCourseInfoById(+id).pipe(
          finalize(() => this.$isCourseLoading.next(false))
        )
      ),
      tap(course => this.course = course),
      tap(course => {
        if (course.isPurchased) {
          this.onPaymentConfirmed();
        }
      }),
      finalize(() => this.$isCourseLoading.next(false)),
    );
  }

  course$ = this.courseObservable$;

  $isCourseLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  $isPaymentLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  currentPaymentMethod$: BehaviorSubject<string> = new BehaviorSubject<string>('yookassa');

  currentCurrency$ = this.currentPaymentMethod$.pipe(
    map(paymentMethod => paymentMethod === 'yookassa' ? 'RUB' : 'USD')
  );
  currentPrice$ = this.course$.pipe(
    switchMap(course => this.currentPaymentMethod$.pipe(
      map(paymentMethod => paymentMethod === 'yookassa' ? course.price_rub : course.price_usd)
    ))
  );

  subscriptions: Subscription[] = [];

  course: CourseModel;
  userId = this.accountService.accountData.value.id;

  constructor(private modalController: ModalController,
              private accountService: AccountService,
              private platform: Platform,
              private coursesService: CoursesService) {
    this.subscriptions.push(
      // works only in apps
      this.platform.resume.subscribe(() => {
        // this.navigateIfPaid();
        // console.log('resume');

        this.reloadPageIfNotPurchased();
      })
    );
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent) {
    // Handle the app resuming from the background
    // console.log('onFocus');
    this.reloadPageIfNotPurchased();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setPaymentMethod(event: any) {
    this.currentPaymentMethod$.next(event.detail.value);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  reloadPageIfNotPurchased() {
    if (this.course?.isPaid && !this.course?.isPurchased) {
      this.course$ = this.courseObservable$;
    }
  }

  onPaymentConfirmed() {
    this.coursesService.getAllHomePageCourses();
    this.coursesService.getAllCoursesAndCoursesCategories();
    this.subscriptions.push(
      of(true).pipe(
        delay(2000)
      ).subscribe(value => this.closeModal())
    );
  }


}
