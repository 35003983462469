import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-promo-modal',
  templateUrl: './promo-modal.component.html',
  styleUrls: ['./promo-modal.component.scss'],
})
export class PromoModalComponent implements OnInit {

  @Input() code: string;
  safeCode: SafeHtml;

  constructor(
    private _domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    if(this.code){
      this.safeCode = this._domSanitizer.bypassSecurityTrustHtml(this.code);
    }
  }

}
