import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AlertController, LoadingController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject, EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PlatformService } from '../shared/services/platform.service';
import { ToastService } from '../shared/services/toast.service';
import { Network } from '@capacitor/network';
import { Curriculum } from '../school/curriculum.model';
import { StorageService } from '../shared/services/storage.service';
import { FirebaseAnalyticsService } from '../shared/services/firebase-analytics.service';
import { AccountData } from '../shared/account-data.model';
import {CoursesService} from '../shared/services/courses.service';
import { SchoolService } from '../school/school.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {

  accountData: BehaviorSubject<AccountData> = new BehaviorSubject<AccountData>(null);
  readonly mazhabList = {
    0: 'не выбран',
    1: 'ханафитский',
    2: 'шафиитский',
    3: 'ханбалитский'
  };

  readonly sexList = {
    0: 'не указан',
    1: 'мужской',
    2: 'женский'
  };

  private _fetchingAccountDataObservable: Observable<AccountData>;

  private _lastViewedNewsPostDate: number = 0;
  get lastViewedNewsPostDate(){
    return this._lastViewedNewsPostDate;
  }

  minPasswordLength = environment.minPasswordLength;

  set lastViewedNewsPostDate(value: number){
    if(value<=this._lastViewedNewsPostDate){ //if already viewed more fresh news post
      return;
    }
    this._lastViewedNewsPostDate = value;
    this._storage.set('lastViewedNewsPostDate',value.toString());
  }

  private _updateAccountDataOnResume: boolean = false;

  curriculumSubscription: Subscription;

  constructor(
    private _httpClient: HttpClient,
    private _toastService: ToastService,
    private _loadingController: LoadingController,
    private _alertController: AlertController,
    private _platformService: PlatformService,
    private _navController: NavController,
    private _storage: StorageService,
    private _platform: Platform,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private _coursesService: CoursesService,
    private _schoolService: SchoolService,
  ) {

    Network.addListener('networkStatusChange', status => {
      if(!status.connected){
        return;
      }
      if(this.accountData.getValue()!=null){
        return;
      }
      this.fetchAccountData().subscribe();
    });

    this._storage.get('accountData', true).then(storedAccountData=>{
      if(<AccountData>storedAccountData){
        this.accountData.next(<AccountData>storedAccountData);
      }
      this.accountData.subscribe(newAccountData=>{
        if(!newAccountData){
          this._storage.remove('accountData');
          return;
        }
        this._storage.set('accountData', newAccountData);
        [
          'id',
          'mazhab',
          'level',
          'sex'
        ].forEach(key=>{
          this._firebaseAnalyticsService.setUserProperty(key,newAccountData[key]);
        });
        this._firebaseAnalyticsService.setUserProperty('lessonsViewed',newAccountData.viewed.length);
        this._firebaseAnalyticsService.setUserProperty('examsPassed',Object.keys(newAccountData.results).length);
      });
    });

    this._storage.get('lastViewedNewsPostDate').then(lastViewedNewsPostDate=>{
      if(lastViewedNewsPostDate){
        this._lastViewedNewsPostDate = +lastViewedNewsPostDate;
      }
    });

    this._platform.resume.subscribe(async () => {
      if(this._updateAccountDataOnResume){
        this._updateAccountDataOnResume = false;
        this.fetchAccountData().subscribe();
      }
    });
  }

  ngOnDestroy() {
    if (this.curriculumSubscription) {
      this.curriculumSubscription.unsubscribe();
    }
  }

  async checkCurrentPassword(currentPassword: string): Promise<boolean>{
    return this._loadingController.create({
      message:'Проверка...',
      mode: this._platformService.nativeMode
    }).then(loading=>{
      loading.present();
      return this._httpClient.post<{result:string}>(`${environment.apiUrl}/checkCurrentPassword`,{currentPassword: currentPassword})
      .toPromise()
      .then(response=>{
        loading.dismiss();
        return true;
      },error=>{
        this._showError(error);
        loading.dismiss();
        return false;
      });
    });
  }

  async changeMail(mail: string): Promise<boolean>{
    return this._loadingController.create({
      message:'Проверка...',
      mode: this._platformService.nativeMode
    }).then(loading=>{
      loading.present();
      return this._httpClient.post<{result:string}>(`${environment.apiUrl}/changeMail`,{mail: mail})
      .toPromise()
      .then(()=>{
        loading.dismiss();
        return true;
      },error=>{
        if(!error?.error?.reason){
          this._toastService.showError('Ошибка! смены почты');
        }
        let message: string;
        switch(error.error.reason){
          case 'mailExists':
            message = `Указаный E-mail уже используется другим пользователем!`;
            break;
          default:
            message = 'Пожалуйста, введите настоящий корректный адрес электронной почты!';
            break;
        }
        this._toastService.showError(message);
        loading.dismiss();
        return false;
      });
    });
  }

  setAccountData(newData: {
    mazhab?: 1|2|3,
    name?:string,
    sex?:1|2,
    password?:string,
    mail?:string,
    unsubscribed?:boolean,
    currentPassword?:string, //needed to set new password
    entryExamPassed?:boolean
  }): Promise<any>{
    this._firebaseAnalyticsService.logEvent('changeAccountData',{
       ...newData,
       currentPassword: null,
       password: newData.password ? 'new password' : null,
       mail: newData.mail ? 'new mail' : null,
    });
    return this._httpClient.post<{result:string,accountData:AccountData}>(`${environment.apiUrl}/setAccountData`,newData)
      .toPromise();
  }

  setUnviewedAnswers(value: number){
    this.accountData.next({
      ...this.accountData.getValue(),
      unviewedAnswers: value
    });
  }

  setViewed(id: number):Promise<boolean>{
    this._firebaseAnalyticsService.logEvent('lessonViewed',{
      id: id
    });

    let accountData = this.accountData.getValue();
    if(!accountData || accountData.viewed.includes(id)){
      return of(false).toPromise();
    }

    return this._httpClient.post<{result:string}>(`${environment.apiUrl}/setViewed`,{id:id}).pipe(
      map(response=>{
        if(!response.result || response.result!='success'){
          return false;
        }
        accountData.viewed.push(id);
        this.accountData.next(accountData);
        // this._coursesService.getAllCourses();
        return true;
      }),
      catchError(error=>{
        this._firebaseAnalyticsService.logEvent('setLessonViewed_error',{id: id});
        return throwError(error);
      })
    ).toPromise();

  }

  isViewed(id:number):boolean{
    const accountData = this.accountData.getValue();
    return accountData && accountData.viewed && accountData.viewed.includes(id);
  }

  accountDataAsPromise():Promise<AccountData>{
    const accountData = this.accountData.getValue();
    return ( accountData ? of(accountData) : this.fetchAccountData() ).toPromise();
  }

  fetchAccountData(): Observable<AccountData>{
    // console.log('fetchAccountData');
    if(this._fetchingAccountDataObservable){
      //returning already created observable
      return this._fetchingAccountDataObservable;
    }
    //create new observable:
    this._fetchingAccountDataObservable = this._httpClient.get<{result:string,accountData:AccountData,curriculum?:Curriculum,reason?:string}>(`${environment.apiUrl}/getAccountData`).pipe(
      catchError(error=>{
        return throwError(error);
      }),
      map(response => {
        if(response.result && response.result=='success' && response.accountData){
          //accountData value sets via HTTPInterceptor
          return response.accountData;
        }else{
          throwError(EMPTY);
        }
      }),
      finalize(()=>{
        //clear saved observable:
        this._fetchingAccountDataObservable = null;
      }),
      publishReplay(),
      refCount()
    );
    return this._fetchingAccountDataObservable;
  }

  uploadAvatar(webPath: string): Promise<any> {
    this._firebaseAnalyticsService.logEvent('uploadAvatar');
    let loading: HTMLIonLoadingElement;
    return this._loadingController.create({
      message: 'Загрузка...',
      mode: this._platformService.nativeMode,
    }).then(_loading=>{
      loading = _loading;
      loading.present();
      return fetch(webPath).then(r => r.blob());
    }).then(blob=>{
      const formData = new FormData();
      formData.append('file', blob, 'newAvatar');
      return this._httpClient.post<{result:string,accountData:AccountData}>(`${environment.apiUrl}/uploadAvatar`, formData)
      .pipe(
        catchError(error => {
          this._showError(error);
          return throwError(error);
        }),
        finalize(()=>{
          loading.dismiss();
        })
      ).toPromise();
    })

  }

  private _showError(error?: HttpErrorResponse){
    if(!error?.error?.reason){
      console.error(error);
      this._toastService.showError('Ошибка! Изменения данных пользователя');
      return;
    }

    let message: string;
    switch(error.error.reason){
      case 'alreadyHasSex':
        message = 'Чтобы изменить пол аккаунта, обратитесь в службу поддержки!'
        break;
      case 'fakeMail':
        message = 'Пожалуйста, введите настоящий корректный адрес электронной почты!'
        break;
      case 'mailAlreadyUsed':
        message = 'Адрес электронной почты уже используется другим пользователем!'
        break;
      case 'wrongNameLength':
        message = 'Длинна имени должна составлять от 2-х до 100 символов!'
        break;
      case 'wrongCurrentPassword':
        message = 'Текущий пароль введен неверно! Если Вы забыли его, воспользуйтесь процедурой восстановления в окне входа.'
        break;
      case 'shortPassword':
          message = `Ошибка: минимальная длина пароля – ${this.minPasswordLength} символов!`
          break;
      default:
        message = 'Неизвестная ошибка! Пожалуйста, проверьте введенные данные или попытайтесь позже.'
    }
    if(!message){
      return;
    }
    this._toastService.showError(message);
  }

  addResult(subjectId: number, result: number):boolean{
    let accountData = this.accountData.getValue();
    if(accountData.results[subjectId] && accountData.results[subjectId]>=result){
      return false;
    }
    accountData.results[subjectId] = result;
    this.accountData.next(accountData);
    return true;
  }

  startMyLevel(): Promise<any>{
    let accountData = this.accountData.getValue();
    if(accountData.level>1){
      return;
    }
    this._firebaseAnalyticsService.logEvent('startFromLevel',{
      level: accountData.entryStage ?? 1
    });
    return this._httpClient.get<{result:string,level:number}>(`${environment.apiUrl}/startMyLevel`).pipe(
      catchError(error=>{
        this._showError(error);
        return throwError(error);
      }),
      tap(response=>{
        if(response.result!=='success' || !response.level || response.level<1){
          throwError('unknown');
        }
        this._navController.navigateRoot(['/home']);
      })
    ).toPromise();
  }

  changeMazhab(): Promise<any>{
    let inputs: any[] = [];
    const currentMazhabId = this.accountData.getValue().mazhab;
    for(const mazhabId in this.mazhabList){
      if(mazhabId === '0' || mazhabId === '3'){
        continue;
      }
      inputs.push({
        type: 'radio',
        checked: currentMazhabId == +mazhabId,
        value: mazhabId,
        label: this.mazhabList[mazhabId],
      })
    }
    return this._alertController.create({
      mode: this._platformService.nativeMode,
      header: 'Выберите мазхаб',
      message: (currentMazhabId==0) ? 'Прежде, чем Вы продолжите, необходимо выбрать правовую школу, в рамках которой Вы будете изучать фикх и связанные с ним дисциплины.' : 'Внимание: если Вы смените мазхаб сейчас, Вам придется пересдать дисциплины, содержание которых зависит от мазхаба!',
      inputs: inputs,
      backdropDismiss: false,
      buttons: [
        {
          text: (currentMazhabId==0)?'Не сейчас':'Отмена',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Как выбирать?',
          role: 'help',
          cssClass: 'secondary',
          handler: ()=>{
            this._navController.navigateForward('mazhab-howto');
          }
        },
        {
          text: (currentMazhabId==0)?'Выбрать':'Изменить',
          role: 'change',
          handler: value =>{
            if(+value == currentMazhabId){
              return;
            }
            if(![1,2,3].includes(+value)){
              this._toastService.show({message:'Пожалуйста, выберите мазхаб!',color:'warning'})
              return false;
            }
            return this._loadingController.create({
              message:'Сохранение...',
              mode: this._platformService.nativeMode
            }).then(loading=>{
              loading.present();
              return this.setAccountData({mazhab:value}).finally(()=>{
                loading.dismiss();
                this._coursesService.getAllHomePageCourses();
                this._schoolService.fetchCurriculum().subscribe();
              });
            })
          }
        },
      ]
    }).then(alert=>{
      alert.present();
      return alert.onDidDismiss();
    }).then(userSelection=>{
      if(!userSelection.role || userSelection.role!=='change'){
        this._firebaseAnalyticsService.logEvent('mazhabSelector_canceled');
      }
      this._firebaseAnalyticsService.logEvent('mazhabSelector_selected');
      return userSelection;
    });
  }

  changeSex(): Promise<any>{
    let inputs: any[] = [];
    const currentSexId = this.accountData.getValue().sex;
    if(currentSexId != 0){
      return;
    }
    ['Мужской', 'Женский'].forEach((label,index)=>{
      const sexId = index+1;
      inputs.push({
        type: 'radio',
        checked: currentSexId == +sexId,
        value: sexId,
        label: label,
      })
    })

    return this._alertController.create({
      mode: this._platformService.nativeMode,
      header: 'Укажите Ваш пол',
      message: 'Некоторый контент в нашей Академии разделен для мужчин и женщин, например, выбинары. Чтобы продолжить, пожалуйста, укажите Ваш пол:',
      inputs: inputs,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Подтвердить',
          role: 'change',
          handler: value =>{
            if(![1,2].includes(+value)){
              this._toastService.show({message:'Пожалуйста, укажите Ваш пол!',color:'warning'})
              return false;
            }
            return this._loadingController.create({
              message:'Сохранение...',
              mode: this._platformService.nativeMode
            }).then(loading=>{
              loading.present();
              return this.setAccountData({sex:value}).finally(()=>{
                loading.dismiss();
              });
            })
          }
        },
      ]
    }).then(alert=>{
      alert.present();
      return alert.onDidDismiss();
    }).then(userSelection=>{
      if(!userSelection.role || userSelection.role!=='change'){
        this._firebaseAnalyticsService.logEvent('genderSelector_canceled');
      }
      this._firebaseAnalyticsService.logEvent('genderSelector_selected');
      return userSelection;
    });
  }

  get isPracticesPassed():boolean{
    const currentAccountData = this.accountData.getValue();
    return ['fatiha','ihlas','tahiyat'].every(practice=>currentAccountData.practices[practice] && currentAccountData.practices[practice]>0);
  }

  get canGoFinalExam():boolean{
    const currentAccountData = this.accountData.getValue();
    if(currentAccountData?.certificate?.issued){
      return false;
    }
    const bestResult = currentAccountData.results['sf'] ?? null;
    if(!bestResult){
      return true;
    }
    return currentAccountData.finalExamTries < currentAccountData.maxFinalExamTries && bestResult < 100;
  }

  get isFinalExamPassed(): boolean{
    const currentAccountData = this.accountData.getValue();
    return currentAccountData && Object.keys(currentAccountData.results).includes('sf');
  }

  updateAccountDataOnResume(){
    this._updateAccountDataOnResume = true;
  }

}

