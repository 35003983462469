import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {App, AppInfo} from '@capacitor/app';
import {AlertController, Platform} from '@ionic/angular';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AccountService} from '../../account/account.service';
import {AuthService} from '../../auth/auth.service';
import {SchoolService} from '../../school/school.service';
import {Palm, PalmService} from './palm.service';
import {PlatformService} from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  private _appBuild: number;
  private _alertedUpdate: boolean;
  private _appInfo: AppInfo;

  constructor(
    private _authService: AuthService,
    private _accountService: AccountService,
    private _schoolService: SchoolService,
    private _ngZone: NgZone,
    private _alertController: AlertController,
    private _platformService: PlatformService,
    private _palmService: PalmService,
    private _platform: Platform,
  ) {
    App.getInfo().then(appInfo => {
      this._appInfo = appInfo;
      this._appBuild = +appInfo.build;
    }).catch(() => {
    });
  }

  getAppVersionHeader(): string {
    let add = ['MedinaApp'];
    if (this._appInfo) {
      add.push(`${this._appInfo.version}.${this._appInfo.build}`);
    }
    add.push(...this._platform.platforms());
    return add.join('/');
  }

  private get _authToken(): string {
    return `${this._authService.id.toString()}+${this._authService.token.toString()}`;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this._authService.isAuthenticated()).pipe(
      switchMap(authenticated => {
        const modifiedRequest = authenticated && (request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.api4Url) || request.url.startsWith(environment.api3Url))
          ? request.clone({headers: request.headers.set('authorization', this._authToken).set('App-Version', this.getAppVersionHeader())})
          : request;

        return next.handle(modifiedRequest).pipe(
          tap(response => {
            if (!response?.hasOwnProperty('body')) {
              return;
            }
            if (response['body']?.hasOwnProperty('accountData') && !this._isEqualObjects(this._accountService.accountData.getValue(), response['body']['accountData'])) {
              this._ngZone.run(() => {
                this._accountService.accountData.next(response['body']['accountData']);
              });
            }
            if (response['body']?.hasOwnProperty('minAppBuild') && this._appBuild && this._appBuild < +response['body']['minAppBuild']) {
              this._updateAlert();
            }
            if (response['body']?.hasOwnProperty('curriculum') && !this._isEqualObjects(this._schoolService.curriculum.getValue(), response['body']['curriculum'])) {
              this._ngZone.run(() => {
                this._schoolService.curriculum.next(response['body']['curriculum']);
              });
            }
            if (response['body']?.hasOwnProperty('currentPalm') && !this._isEqualObjects(this._palmService.palm.getValue(), response['body']['currentPalm'])) {
              this._ngZone.run(() => {
                this._palmService.palm.next(<Palm>response['body']['currentPalm']);
              });
            }
          }),
          catchError((error: HttpErrorResponse) => {
            this._authService.isAuthenticated().then(isAuthenticated => {
              if (isAuthenticated && error.error && error.error.reason && error.error.reason == 'notAuthentificated') {
                console.warn('Token is outdated, logout.');
                this._authService.logout();
              }
            });

            return throwError(error);
          })
        );
      })
    );


  }

  private _isEqualObjects(object1, object2): boolean {
    return JSON.stringify(object1) == JSON.stringify(object2);
  }

  private _updateAlert() {
    if (this._alertedUpdate) {
      return;
    }
    this._alertedUpdate = true;
    this._alertController.create({
      mode: this._platformService.nativeMode,
      header: 'Необходимо обновление',
      message: 'Пожалуйста, установите обновление из магазина приложений! В противном случае, некоторые функции могут работать неправильно.',
      buttons: [
        {
          text: 'OK',
        }
      ]
    }).then(alert => alert.present());
  }
}
