import { Injectable } from '@angular/core';
import { AlertController, isPlatform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(
    private _alertController: AlertController
  ) { }

  get nativeMode(): 'ios'|'md'{
    return isPlatform('ios') ? 'ios' : 'md';
  }

  public checkAndroidWebkitVersion(){
    if(!isPlatform('android') || !isPlatform('capacitor') || !navigator?.userAgent){
      return;
    }
    const matches = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if(!matches){
      return;
    }
    const version = parseInt(matches[2], 10);
    if(version<environment.minWebViewVersion){
      this._alertController.create({
        header: 'Требуется обновление',
        message: 'Системный компонент Android WebView на этом устройстве устарел! Чтобы все функции приложения работали правильно, его необходимо обновить и перезагрузить устройство.',
        buttons: [
          {
            text: 'Отмена',
            role: 'cancel'
          },
          {
            text: 'Обновить',
            handler: ()=>{
              window.open(environment.webViewGooglePlayUrl, '_system');
            }
          },
        ],
      }).then(alert=>alert.present());
    }
  }
}
