import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attention-marker',
  template: '',
  styleUrls: ['./attention-marker.component.scss'],
})
export class AttentionMarkerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
