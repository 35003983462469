import {Directive, ElementRef, Inject, OnDestroy} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[appDragToScroll]'
})
export class DragToScrollDirective implements OnDestroy {
  private element: HTMLElement;

  private subscriptions: Subscription[] = [];

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: any) {
    this.element = this.elementRef.nativeElement as HTMLElement;
    this.initDrag();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  initDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(this.element, 'mousedown');
    const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
    const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
      takeUntil(dragEnd$)
    );

    let dragSub: Subscription;

    const dragStartSub = dragStart$.subscribe((dragStartEvent: MouseEvent) => {

      const initialLeft: number = this.element.scrollLeft;
      const initialTop: number = this.element.scrollTop;

      dragSub = drag$.subscribe((dragEvent: MouseEvent) => {
        this.element.style.cursor = 'grabbing';
        this.element.setAttribute('style', 'scroll-snap-type: none');

        this.element.scrollTop = initialTop - (dragEvent.clientY - dragStartEvent.clientY);
        this.element.scrollLeft = initialLeft - (dragEvent.clientX - dragStartEvent.clientX);
      });
    });

    const dragEndSub = dragEnd$.subscribe(() => {
      this.element.style.cursor = 'auto';
      this.element.setAttribute('style', 'scroll-snap-type: x mandatory');
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    this.subscriptions.push.apply(this.subscriptions, [
      dragStartSub,
      dragSub,
      dragEndSub,
    ]);
  }

}
