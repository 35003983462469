<div class="card-container" [routerLink]="routerLink">

  <div class="card-header">
    <span #cardTitle
          class="title">
      {{ card.title }}
    </span>
    <span class="level-chip"
          [class.level-chip-default]="isCourseStartedNotFinished"
          [class.level-chip-complete]="isCourseFinished">
      <span *ngIf="isCourseStartedNotFinished">
        Уровень {{ card.level }}
      </span>
      <span *ngIf="isCourseNotStarted && card.isPurchased">
        {{ 'Доступен' }}
      </span>
      <span *ngIf="isCourseNotStarted && !card.isPurchased">
        {{ card.isPaid ? (card.price_rub | currency: 'RUB': 'symbol-narrow':'1.0-0':'ru') : 'Бесплатно' }}
      </span>
      <span *ngIf="isCourseFinished">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M5.56143 0.285456C5.74083 -0.0951521 6.25917 -0.0951521 6.43857 0.285456L7.91379 3.41526C7.98503 3.5664 8.12273 3.67115 8.28203 3.69539L11.5807 4.19728C11.9819 4.25831 12.142 4.77448 11.8518 5.07075L9.46482 7.50696C9.34955 7.6246 9.29695 7.7941 9.32416 7.96022L9.88764 11.4002C9.95617 11.8185 9.53682 12.1375 9.17803 11.94L6.22759 10.3159C6.08511 10.2375 5.91489 10.2375 5.77242 10.3159L2.82198 11.94C2.46318 12.1375 2.04383 11.8185 2.11236 11.4002L2.67584 7.96022C2.70305 7.7941 2.65045 7.6246 2.53518 7.50696L0.148228 5.07075C-0.142044 4.77448 0.0181319 4.25831 0.419279 4.19728L3.71797 3.69539C3.87727 3.67115 4.01497 3.5664 4.08621 3.41526L5.56143 0.285456Z" fill="#FCAA00"/>
        </svg>
        Пройден!
      </span>
    </span>
  </div>



  <div class="card-content">
    <div class="aboutCourse">
      <div class="aboutCourseHeader">
        <div class="description"
             [style.-webkit-line-clamp]="isTitleTwoLines(cardTitle) ? 2 : 3"
             [style.max-height]="isTitleTwoLines(cardTitle) ? '2.48rem' : '3.48rem'"
             [innerHTML]="(isCourseStartedNotFinished ? ('Пройдено: ' + card.viewed_count + '/' + card.max_lessons_number ) : card.short_description) | safeHtml">
        </div>
      </div>

      <div class="nextLessonContainer"
           *ngIf="isCourseStartedNotFinished">
        <div>
          <svg [style.fill]="card.main_color" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM13.8893 9.99999C13.8893 9.16679 13.2921 8.67963 12.0978 7.70532C11.7681 7.43636 11.4411 7.18313 11.1406 6.97212C10.8769 6.787 10.5784 6.5955 10.2692 6.40753C9.07769 5.68294 8.48191 5.32064 7.94756 5.72176C7.41321 6.12289 7.36465 6.9626 7.26752 8.64204C7.24006 9.11699 7.22266 9.58259 7.22266 9.99999C7.22266 10.4174 7.24006 10.883 7.26752 11.3579C7.36465 13.0374 7.41321 13.8771 7.94756 14.2782C8.48191 14.6793 9.07769 14.317 10.2692 13.5924C10.5784 13.4045 10.8769 13.213 11.1406 13.0279C11.4411 12.8168 11.7681 12.5636 12.0978 12.2946C13.2921 11.3203 13.8893 10.8332 13.8893 9.99999Z"/>
          </svg>
        </div>
        <div class="description" *ngIf="isExamNext">
          Экзамен
        </div>
        <div class="description" *ngIf="!isExamNext"
             [innerHTML]="(card.next_lesson_title ? card.next_lesson_title : card.short_description) | safeHtml">
        </div>
      </div>

      <div class="teacher-container"
           *ngIf="!isCourseStartedNotFinished">
        <div class="teacherName-container">
          <span class="teacher-label">Преподаватель</span>
          <span class="teacherName">{{ card.teacher_name }}</span>
        </div>
      </div>
      <!--      <div class="nextLesson" *ngIf="isCourseStartedNotFinished">-->
      <!--        <span *ngIf="isExamNext; else nextLesson">Продолжить: Экзамен</span>-->
      <!--        <ng-template #nextLesson>-->
      <!--          <span>Продолжить: Урок {{ card.next_lesson_number }}</span>-->
      <!--        </ng-template>-->
      <!--      </div>-->
    </div>

    <div class="image-container">
      <img class="image" [src]="card.image_url">
    </div>
  </div>

  <div class="progress-container"
       *ngIf="isCourseStartedNotFinished">
    <div class="progressBar"
         [style.background-color]="card.main_color + '7F'"
         [style.min-width]="isCourseFinished ? '100%' : '120%'">

      <div class="progressLine"
           [style.background-color]="card.main_color"
           [style.width]="card.progressPercents + '%'">
      </div>

    </div>
  </div>
</div>
