<div class="palm-content" [class.disabled]="disabled">
  <img [src]="baseImgSrc" [ngStyle]="imgStyle">
  <img [src]="imageSrc" [ngStyle]="imgStyle">
  <svg
    class="progress-ring"
    [attr.width]="size"
    [attr.height]="size">
    <circle
      stroke="var(--ion-color-light)"
      [attr.stroke-width]="stroke"
      fill="transparent"
      [attr.r]="radius"
      [attr.cx]="size/2"
      [attr.cy]="size/2"/>
    <circle
      class="progress"
      stroke="var(--ion-color-warning)"
      [attr.stroke-width]="stroke"
      fill="transparent"
      [attr.r]="radius"
      [attr.cx]="size/2"
      [attr.cy]="size/2"
      [ngStyle]="{strokeDasharray:circumference+' '+circumference, strokeDashoffset:strokeDashoffset}"/>
  </svg>
</div>
