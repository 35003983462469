import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Curriculum } from './curriculum.model';
import { MediaContent } from '../shared/media-content.model';
import { MediaCategory } from '../shared/media-category.model';
import { StorageService } from '../shared/services/storage.service';
import { Network } from '@capacitor/network';
import { catchError, finalize, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  curriculum: BehaviorSubject<Curriculum> = new BehaviorSubject<Curriculum>(null);

  private _fetchingCurriculumObservable: Observable<Curriculum>;

  constructor(
    private _httpClient: HttpClient,
    private _storage: StorageService
  ) {
    Network.addListener('networkStatusChange', status => {
      if(!status.connected){
        return;
      }
      if(this.curriculum.getValue()!=null){
        return;
      }
      this.fetchCurriculum().subscribe();
    });

    this._storage.get('curriculum',true).then(storedCurriculum=>{
      if(<Curriculum>storedCurriculum){
        this.curriculum.next(<Curriculum>storedCurriculum);
      }

      this.curriculum.subscribe(newCurriculum=>{
        if(!newCurriculum){
          this._storage.remove('curriculum');
          return;
        }
        this._storage.set('curriculum', newCurriculum);
      });
    });
  }

  fetchCurriculum(): Observable<Curriculum>{
    if(this._fetchingCurriculumObservable){
      //returning already created observable
      return this._fetchingCurriculumObservable;
    }
    //create new observable:
    this._fetchingCurriculumObservable = this._httpClient.get<{result:string,curriculum:Curriculum}>(`${environment.apiUrl}/getCurriculum`).pipe(
      publishReplay(),
      refCount(),
      catchError(error=>{
        return throwError(error);
      }),
      map(response => {
        if(response.result && response.result=='success' && response.curriculum){
          // console.log('curriculum', response.curriculum);
          return response.curriculum;
        }else{
          throwError(EMPTY);
        }
      }),
      finalize(()=>{
        //clear saved observable:
        this._fetchingCurriculumObservable = null;
      }),
    );

    return this._fetchingCurriculumObservable;
  }

  getContentFromCurriculum(contentId: number): MediaContent{
    const curriculum = this.curriculum.getValue();
    if(!curriculum){
      return null;
    }
    let foundContent: MediaContent = null;
    levelLoop: for (const levelSubjects of Object.values(curriculum)) {
      for (const subject of Object.values(levelSubjects)) {
        if(!subject['lessons']){
          continue;
        }
        const lessonIndex: number = Object.values(subject['lessons']).findIndex(lesson=>{
          return lesson && lesson['id'] && lesson['id'] == contentId;
        });
        if(lessonIndex>-1){
          foundContent = {
            ...subject['lessons'][lessonIndex],
            navigation:{
              currentNumber: lessonIndex+1,
              nextId: subject['lessons'][lessonIndex+1] ? subject['lessons'][lessonIndex+1]['id'] : null,
              prevId: lessonIndex>0 ? subject['lessons'][lessonIndex-1]['id'] : null,
            }
          };
          break levelLoop;
        }
      }
    }
    return foundContent;
  }

  getCategoryFromCurriculum(id: number|string, level?:number): MediaCategory{
    const curriculum = this.curriculum.getValue();
    if(!curriculum){
      return null;
    }

    if(level){
      if(!curriculum[level]){
        return null;
      }
      return curriculum[level].find(category=>{
        return category.id == id;
      });
    }

    let foundCategory: MediaCategory = null;

    for (const curriculumLevel of <MediaCategory[][]>Object.values(curriculum)) {
      foundCategory = curriculumLevel.find(category=>{
        return category.id==id;
      })
      if(foundCategory){
        break;
      }
    }

    return foundCategory;
  }

  get levelsCount(): number {
    return Object.keys(this.curriculum.getValue()).length;
  }

}
