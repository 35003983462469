import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';


@Component({
  selector: 'app-about-teacher-modal',
  templateUrl: './about-teacher-modal.component.html',
  styleUrls: ['./about-teacher-modal.component.scss'],
})
export class AboutTeacherModalComponent {

  @Input() teacherAvatar: string;
  @Input() teacherName: string;
  @Input() teacherShortDescription: string;
  @Input() teacherDescription: string;

  constructor(private _modalController: ModalController) { }


  closeModal() {
    this._modalController.dismiss();
  }


}
