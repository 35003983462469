import {ComponentFactoryResolver, Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';
import {SkeletonLoadingComponent} from '../skeleton-loading/skeleton-loading.component';

@Directive({
  selector: '[skeleton]'
})
export class SkeletonLoadingDirective implements OnChanges{

  @Input('skeleton') isLoading = false;
  @Input('skeletonRepeat') repeat = 1;
  @Input('skeletonWidth') width: string;
  @Input('skeletonHeight') height: string;
  @Input('skeletonClassName') className: string;
  @Input('skeletonStyle') style: string;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private resolver: ComponentFactoryResolver) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.viewContainer.clear();

      if (changes.isLoading.currentValue) {
        Array.from({ length: this.repeat }).forEach(() => {
          const resolvedSk = this.resolver.resolveComponentFactory(SkeletonLoadingComponent);
          const ref = this.viewContainer.createComponent(resolvedSk);

          Object.assign(ref.instance, {
            width: this.width,
            height: this.height,
            className: this.className,
            style: this.style
          });
        });
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }

}
