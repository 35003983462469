import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, CanLoad, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard implements CanActivate, CanDeactivate<unknown>, CanLoad {
  constructor(
    private _authService: AuthService,
  ){}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._checkNotAuth();
  }
  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._checkNotAuth();
  }
  canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authService.isAuthenticated();
  }

  private _checkNotAuth(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authService.isAuthenticated().then(isAuthenticated => {
      if (isAuthenticated){
        this._authService.navigateMain();
      }
      return !isAuthenticated;
    });
  }
}
