<div class="carouselContainer"
     (mouseenter)="setIsHovered(true)"
     (mouseleave)="setIsHovered(false)">
  <div class="desktopButtonsOverlay">

    <div class="sideContainer">
      <div class="leftButton" *ngIf="itemsContainer.scrollLeft != 0">
        <div class="scrollButton"
             *ngIf="isHovered"
             (click)="scrollLeft(itemsContainer)">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
      </div>
    </div>

    <div class="sideContainer">
      <div class="rightButton"
           *ngIf="!(itemsContainer.offsetWidth + itemsContainer.scrollLeft >= itemsContainer.scrollWidth)">
        <div class="scrollButton"
             *ngIf="isHovered"
             (click)="scrollRight(itemsContainer)">
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      </div>
    </div>

  </div>

  <div #itemsContainer
       class="itemsContainer"
       (scroll)="onCarouselScroll($event)">

    <ng-content></ng-content>

  </div>

  <div class="indicationDotsContainer"
       *ngIf="isItemsIndicatorShow">
    <ng-container *skeleton="isLoading; height: '10px'; width: '60px'">
      <div class="indicatorDot"
           *ngFor="let card of [].constructor(itemsContainer.children.length); let cardIndex = index"
           [class.indicatorDot-active]="currentItemIndex === cardIndex">
      </div>
    </ng-container>
  </div>
</div>
