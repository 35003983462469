<div class="modalMainContainer">

  <section class="modalHeaderContainer">
    <div class="modalHeader">
      <ion-icon name="close-outline" [style.visibility]="'hidden'"></ion-icon>
      <ion-icon (click)="closeModal()" name="close-outline"></ion-icon>
    </div>
  </section>

  <ng-container *observableContext="(course$ | async); let course">
    <div class="modalContent" *ngIf="course?.isPurchased !== true; else purchasedTemplate">
      <div class="modalTitle">
        Оплата курса:
        <span *skeleton="$isCourseLoading | async; height: '30px'; width: '100%';">
          "{{ course?.title }}"
        </span>
      </div>
      <div class="coursePrice">
        Стоимость:
        <span *skeleton="$isCourseLoading | async; height: '30px'; width: '100%';">
          {{ (currentPrice$ | async) | currency: (currentCurrency$ | async): 'symbol-narrow':'1.0-0':'ru' }}
        </span>
      </div>

      <div class="paymentContainer ion-padding-horizontal">

        <ion-text class="choosePaymentMethodText">
          Выберите метод оплаты
        </ion-text>

        <ion-list>
          <ion-radio-group (ionChange)="setPaymentMethod($event)"
                           [value]="this.currentPaymentMethod$ | async">

            <ion-item>
              <ion-label class="ion-text-wrap">
                Для оплаты банковской картой, выпущенной на территории Российской федерации
              </ion-label>
              <ion-radio value="yookassa"></ion-radio>
            </ion-item>

            <ion-item lines="none">
              <ion-label class="ion-text-wrap">
                Для оплаты банковской картой, выпущенной за пределами
                Российской федерации
              </ion-label>
              <ion-radio value="stripe"></ion-radio>
            </ion-item>

          </ion-radio-group>
        </ion-list>

        <ion-button
          class="ion-margin-vertical"
          expand="block"
          color="primary"
          *skeleton="$isCourseLoading | async; height: '50px'; width: '100%';"
          [href]="'https://medinaschool.org/pay?product_type=course&product_id=' + course?.id +'&gateway='+ currentPaymentMethod$?.value + '&user_id=' +  userId"
          target="_blank"
          [disabled]="$isPaymentLoading | async">
          <ion-spinner *ngIf="$isPaymentLoading | async" name="dots"></ion-spinner>
          <span *ngIf="!($isPaymentLoading | async)">
                Оплатить
                <span>
                  {{ (currentPrice$ | async) | currency: (currentCurrency$ | async): 'symbol-narrow':'1.0-0':'ru' }}
                </span>
              </span>
        </ion-button>


      </div>

    </div>

    <ng-template #purchasedTemplate>
      <div class="modalContent">
        <div class="completeIconContainer">
          <svg width="118" height="117" viewBox="0 0 118 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M59 10.9688C32.7915 10.9688 11.4688 32.2915 11.4688 58.5C11.4688 84.7085 32.7915 106.031 59 106.031C85.2085 106.031 106.531 84.7085 106.531 58.5C106.531 32.2915 85.2085 10.9688 59 10.9688ZM83.7368 42.5702L53.0243 79.1327C52.6874 79.534 52.2682 79.8581 51.7951 80.0833C51.322 80.3084 50.806 80.4292 50.2821 80.4375H50.2204C49.708 80.4373 49.2012 80.3294 48.7332 80.1208C48.2651 79.9121 47.8461 79.6074 47.5034 79.2264L34.3409 64.6014C34.0066 64.2468 33.7466 63.829 33.5761 63.3725C33.4055 62.9161 33.328 62.4301 33.348 61.9432C33.368 61.4563 33.4851 60.9784 33.6924 60.5374C33.8998 60.0964 34.1932 59.7013 34.5554 59.3754C34.9176 59.0494 35.3413 58.7992 35.8016 58.6393C36.262 58.4794 36.7496 58.4132 37.2359 58.4445C37.7221 58.4757 38.1973 58.6039 38.6333 58.8214C39.0694 59.039 39.4575 59.3414 39.775 59.7111L50.1245 71.21L78.1382 37.8673C78.7666 37.1408 79.6556 36.6908 80.6131 36.6145C81.5706 36.5382 82.5197 36.8418 83.2552 37.4596C83.9907 38.0774 84.4535 38.9598 84.5437 39.9161C84.6338 40.8725 84.3439 41.8258 83.7368 42.5702Z" fill="#00CB9F"/>
          </svg>
        </div>
        <div class="completeTextContainer">
          Курс оплачен! Спасибо!
        </div>
      </div>
    </ng-template>
  </ng-container>

</div>
