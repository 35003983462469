import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CourseCard } from '../Models/courses-models/course-card.model';
import { CourseProgressStatusEnum } from '../Models/courses-models/course-progress-status.enum';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseCardComponent {
  @Input() card: CourseCard;

  constructor() {
  }

  get routerLink(): any[] | string | null | undefined {
    if (this.isCourseStartedNotFinished) {
      if (this.isExamNext) {
        return ['/exam', this.card.category_id];
      } else {
        return this.card.next_lesson_id ? ['/play', this.card.next_lesson_id] : ['/play', this.card.next_lesson_id];
      }
    } else {
      return ['/course', this.card.url];
    }
  }

  get isExamNext(): boolean {
    return !this.card.exam_result_id && !this.card.next_lesson_id;
  }

  get isCourseStartedNotFinished(): boolean {
    return this.card.course_progress_status === CourseProgressStatusEnum.StartedNotFinished;
  }

  get isCourseNotStarted(): boolean {
    return this.card.course_progress_status === CourseProgressStatusEnum.NotStarted;
  }

  get isCourseFinished(): boolean {
    return this.card.course_progress_status === CourseProgressStatusEnum.Finished;
  }

  isTitleTwoLines(cardTitleElement: Element) {
    if (!cardTitleElement) {
      return;
    }

    const titleLineHeight = window.getComputedStyle(cardTitleElement).lineHeight;
    const titleLineHeightRounded = Math.round(
      +titleLineHeight.replace('px', '')
    );

    return titleLineHeightRounded < cardTitleElement.clientHeight;
  }
}
