import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-certificate-payment-progress',
  templateUrl: './course-certificate-payment-progress.component.html',
  styleUrls: ['./course-certificate-payment-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseCertificatePaymentProgressComponent {
  steps = [];

  @Input() currentStep: number;
  @Input() set isFree(value: boolean)  {
    if (value) {
      this.steps = [
        {number: 1, name: 'Оформление'},
        {number: 2, name: 'Получение'},
      ];
    } else {
      this.steps = [
        {number: 1, name: 'Оформление'},
        {number: 2, name: 'Оплата'},
        {number: 3, name: 'Получение'},
      ];
    }
  }



  constructor() {
  }

}
