import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Clipboard } from '@capacitor/clipboard';
import { environment } from '../../../environments/environment';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {

  url = '';
  title = 'Расскажи друзьям о нас';
  shareText =
    'Начни получать знания об Исламе вместе с Академией Медина. \n' +
    '\n' +
    'Ведь наш Пророк (ﷺ) сказал: “Превосходство знающего над поклоняющимся подобно моему превосходству над нижайшим из вас” (ат-Тирмизи).';

  shareLink = environment.webPlatformUrl;

  constructor(private modalController: ModalController,
              private toastService: ToastService) {
  }

  closeModal() {
    this.modalController.dismiss();
  }

  copyShareLink = async () => {
    await Clipboard.write({
      string: this.shareLink
    }).then(() => {
      this.toastService.show({
        message: 'Ссылка скопированна!',
        duration: 2000
      });
    });
  }


  ngOnInit() {
    this.shareLink += this.url;
  }

}
