import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  @Input() isItemsIndicatorShow = true;
  @Input() isLoading = false;

  currentItemIndex = 0;
  isHovered = false;

  constructor() {
  }

  onCarouselScroll(event) {

    const cardWidth = event?.target?.children[0]?.offsetWidth;
    const scrollPosition = event?.target?.scrollLeft + cardWidth;
    const collection: { offsetLeft: number }[] = Array.from(event?.target?.children);

    let newIndex: number = null;
    collection.forEach((el, index) => {

      const offsetLeft = Math.abs(event?.target?.offsetLeft - el.offsetLeft);
      if (scrollPosition > offsetLeft && scrollPosition < Math.abs(event?.target?.offsetLeft - collection[index + 1]?.offsetLeft)) {
        newIndex = index;
        return;
      }
      if (scrollPosition > offsetLeft && !collection[index + 1]) {
        newIndex = index;
      }
    });

    if (newIndex) {
      this.currentItemIndex = newIndex;
    } else {
      this.currentItemIndex = 0;
    }
  }

  scrollRight(listElement: HTMLElement) {
    listElement.scrollLeft += listElement.children[0].clientWidth;
  }

  scrollLeft(listElement: HTMLElement) {
    listElement.scrollLeft -= listElement.children[0].clientWidth;
  }

  setIsHovered(state: boolean) {
    this.isHovered = state;
  }

}
