import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { map, Subscription } from 'rxjs';
import { FullscreenService } from '../services/fullscreen.service';
import { PlayService } from '../../play/play.service';
import { FirebaseAnalyticsService } from '../services/firebase-analytics.service';
import { AmplitudeEventsEnum, AmplitudeService } from '../services/amplitude.service';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Router } from '@angular/router';
import { MainPanelImage } from '../main-panel-image.model';
import { AccountService } from '../../account/account.service';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomBarComponent implements OnInit, OnDestroy {

  tabsList = [
    {
      title: 'Главная',
      name: 'home',
    },
    {
      title: 'Курсы',
      name: 'courses',
    },
    {
      title: 'Рамадан',
      name: 'ramadan',
    },
    {
      title: 'Прогресс',
      name: 'map',
    },
    {
      title: 'Урок',
      name: 'play',
    },
  ];

  @Input() currentTab = this.tabsList[0].name;

  @ViewChild('tabBar', {read: ElementRef}) tab: ElementRef;
  tabEl: ElementRef;

  isVideoFullscreen$ = this._fullScreenService.isFullscreen;
  isShowPlayButton$ = this._playService.contentId.pipe(map(contentId => !!contentId));

  navPanelImage: MainPanelImage = this.accountService.accountData.value.navPanelImage;
  palmSize = 76;
  palmStroke = 3;

  subscriptions: Subscription[] = [];

  constructor(
    private _renderer: Renderer2,
    private _fullScreenService: FullscreenService,
    private _playService: PlayService,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private amplitudeAnalytics: AmplitudeService,
    private router: Router,
    private accountService: AccountService,
  ) {

    this.subscriptions.push(
      this.accountService.accountData.subscribe(value => {
        if (value.navPanelImage) {
          this.tabsList = [
            {
              title: 'Главная',
              name: 'home',
            },
            {
              title: 'Курсы',
              name: 'courses',
            },
            {
              title: 'Рамадан',
              name: 'special',
            },
            {
              title: 'Прогресс',
              name: 'map',
            },
            {
              title: 'Урок',
              name: 'play',
            },
          ];
        } else {
          this.tabsList = [
            {
              title: 'Главная',
              name: 'home',
            },
            {
              title: 'Курсы',
              name: 'courses',
            },
            {
              title: 'Прогресс',
              name: 'map',
            },
            {
              title: 'Урок',
              name: 'play',
            },
          ];
        }
      }));
  }

  ngOnInit() {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', () => {
        this._renderer.addClass(this.tab.nativeElement, 'hide');
      });
      Keyboard.addListener('keyboardWillHide', () => {
        this._renderer.removeClass(this.tab.nativeElement, 'hide');
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onTabButtonClick(tab: string) {
    this.handelAnalytics(tab);
    this.router.navigate(['/' + tab]);
  }

  handelAnalytics(tab: string) {
    if (tab === 'play') {
      this.amplitudeAnalytics.trackEvent({
        eventInput: AmplitudeEventsEnum.currentLesson,
      });
    }
    this._firebaseAnalyticsService.logEvent('tabChange', {
      tab
    });
  }

}
