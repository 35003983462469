import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { map } from 'rxjs';
import { FullscreenService } from '../services/fullscreen.service';
import { PlayService } from '../../play/play.service';
import { FirebaseAnalyticsService } from '../services/firebase-analytics.service';
import { AmplitudeEventsEnum, AmplitudeService } from '../services/amplitude.service';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomBarComponent implements OnInit {

  tabsList = [
    {
      title: 'Главная',
      name: 'home',
    },
    {
      title: 'Курсы',
      name: 'courses',
    },
    {
      title: 'Прогресс',
      name: 'map',
    },
    {
      title: 'Урок',
      name: 'play',
    },
  ];

  @Input() currentTab = this.tabsList[0].name;

  @ViewChild('tabBar', {read: ElementRef}) tab: ElementRef;
  tabEl: ElementRef;

  isVideoFullscreen$ = this._fullScreenService.isFullscreen;
  isShowPlayButton$ = this._playService.contentId.pipe(map(contentId =>  !!contentId));

  constructor(
    private _renderer: Renderer2,
    private _fullScreenService: FullscreenService,
    private _playService: PlayService,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private amplitudeAnalytics: AmplitudeService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', () => {
        this._renderer.addClass(this.tab.nativeElement, 'hide');
      });
      Keyboard.addListener('keyboardWillHide', () => {
        this._renderer.removeClass(this.tab.nativeElement, 'hide');
      });
    }
  }

  onTabButtonClick(tab: string) {
    this.handelAnalytics(tab);
    this.router.navigate(['/' + tab]);
  }

  handelAnalytics(tab: string) {
    if (tab === 'play') {
      this.amplitudeAnalytics.trackEvent({
        eventInput: AmplitudeEventsEnum.currentLesson,
      });
    }
    this._firebaseAnalyticsService.logEvent('tabChange', {
      tab
    });
  }

}
