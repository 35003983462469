<div class="categoryCardContainer"
     [routerLink]="['/courses-recommendation', coursesRecommendation.id]">
  <div class="categoryCard"
       [style.background]="'url(' + coursesRecommendation.image_url +')'"
       [class.categoryCard-active]="isActive">
    <div class="cardContent">

      <div class="cardHeader" *ngIf="isActive">
        <div class="countBadge" *ngIf="coursesRecommendation.courses_count">
          <div class="dot"></div>
          <span>
            {{ coursesRecommendation.courses_count }} {{ coursesRecommendation.courses_count === 1 ? 'курс' : (coursesRecommendation.courses_count > 3 ? 'курсов' : 'курса') }}
          </span>
        </div>
      </div>

      <div class="cardFooter" *ngIf="isActive">
        <span class="title">
          {{ coursesRecommendation.name ?? '' }}
        </span>
          <span class="description">
          {{ coursesRecommendation.description ?? '' }}
        </span>
      </div>

    </div>
  </div>
</div>


