<ion-item
(click)="toggle()"
[detail]="expandable"
detailIcon="chevron-down-outline"
[button]="expandable"
[color]="expanded?color:''"
[class.expanded]="expanded"
[lines]="expanded?'full':'inset'"
class="expand-button">
  <ng-content select=".button"></ng-content>
</ion-item>

<ion-item
*ngIf="expanded"
[@expand]
class="expandable"
[color]="color"
[lines]="expanded?'full':'inset'">
  <ng-content></ng-content>
</ion-item>
