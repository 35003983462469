<ion-app>
  <ion-menu
  side="start"
  menuId="first"
  contentId="mainContent"
  swipe-gesture="false">
    <ion-content class="menu-content">
      <ion-grid class="menu-grid grid-full-height">
        <ion-row class="menu-list-row">
          <ion-col class="menu-list-col ion-no-padding">
            <ion-list class="menu-list" (click)="toggleMenu()" lines="none">
              <ion-item class="logo-container">
                <img class="logo" src="./assets/logo.svg">
              </ion-item>
              <ion-item routerLink="/news">Новости</ion-item>
              <ion-item routerLink="/webinars">Вебинары</ion-item>
              <ion-item *ngIf="accountService?.accountData?.value?.isDonateButtonsShow"
                        (click)="onArabicClick()"
                        [href]="arabicUrl"
                        target="_blank">
                Арабский язык
                <ion-icon name="open-outline" class="ion-padding-start" size="small"></ion-icon>
              </ion-item>
              <ion-item (click)="onAboutProjectClick()" [href]="aboutProjectUrl" target="_blank">
                О проекте
                <ion-icon name="open-outline" class="ion-padding-start" size="small"></ion-icon>
              </ion-item>
              <ion-item routerLink="/faq">Частые вопросы</ion-item>
              <ion-item button (click)="getSupport()">
                Служба поддержки
                <app-attention-marker *ngIf="isUnreadedSupport"></app-attention-marker>
              </ion-item>

              <ion-item-divider></ion-item-divider>

              <ion-item *ngIf="accountService?.accountData?.value?.isDonateButtonsShow"
                        (click)="onDonateClick()"
                        [href]="accountService?.accountData?.value?.donationLink ? accountService?.accountData?.value?.donationLink : donateUrl"
                        target="_blank"
                        [color]="donateItemStyle?.background">
                <ion-label [color]="donateItemStyle?.color">Поддержать проект</ion-label>
                <ion-icon name="open-outline" class="ion-padding-start" size="small"></ion-icon>
              </ion-item>
              <ion-item button (click)="openShareModal()">Пригласить друзей</ion-item>

              <ion-item button [routerLink]="['organization-contacts']">Реквизиты и контакты</ion-item>
            </ion-list>


          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="mainContent" [animated]="true"></ion-router-outlet>
</ion-app>
