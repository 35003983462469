import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MenuController, ModalController} from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SupportService } from './shared/services/support.service';
import { ToastService } from './shared/services/toast.service';
import { FirebaseAnalyticsService } from './shared/services/firebase-analytics.service';
import { AccountService } from './account/account.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { PlatformService } from './shared/services/platform.service';
import { FirebaseRemoteConfigService } from './shared/services/firebase-remote-config.service';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import { ActivatedRoute, Router } from "@angular/router";
import {ShareModalComponent} from "./shared/share-modal/share-modal.component";
import { CoursePurchasingModalComponent } from './shared/course-purchasing-modal/course-purchasing-modal.component';
import { DonateModalComponent } from './shared/donate-modal/donate-modal.component';
import { PromoModalComponent } from './shared/promo-modal/promo-modal.component';
import { PageModalComponent } from './shared/page-modal/page-modal.component';
import { RamadanHttpRequestsService } from './shared/Services/http-requests/ramadan-http-requests.service';
import { StorageService } from './shared/Services/storage.service';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{

  donateUrl: string;
  aboutProjectUrl: string = environment.aboutProjectUrl;
  arabicUrl: string = environment.arabicUrl;

  donateItemStyle: {background: string, color: string};

  isUnreadedSupport: boolean = false;
  private _unreadedSupportSubscription: Subscription;
  private _urlModalsSubscription: Subscription;

  constructor(
    private _menuController: MenuController,
    private _supportService: SupportService,
    private _toastService: ToastService,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    public accountService: AccountService,
    private _platformService: PlatformService,
    private _firebaseRemoteConfigService: FirebaseRemoteConfigService,
    private _zone: NgZone,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _modalController: ModalController,
  ) {
    this.addDeepLinksListener();
  }

  addDeepLinksListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {

      this._zone.run(() => {
        const domain = 'web.medinaschool.org';

        const pathArray = event.url.split(domain);

        const path = pathArray.pop();

        if (path) {
          this._router.navigateByUrl(path);
        }

      });
    });
  }

  openModalFromUrl() {
    // console.log(this._activatedRoute.snapshot.queryParamMap.get('modal'));
    // const modalString = this._activatedRoute.snapshot.queryParamMap.get('modal');
    this._urlModalsSubscription = this._activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      switch (params['modal'] ) {
        case 'donate': {
          this.openDonateModal();
          break;
        }
        case 'share': {
          this.openShareModal();
          break;
        }
      }
    });
  }

  ngOnInit(){
    this._initPlugins();

    this._platformService.checkAndroidWebkitVersion();

    this.accountService.accountData.subscribe(accountData=>{
      this.donateUrl = environment.donateUrl;
      if(accountData){
        this.donateUrl += '&user=' + accountData.id;
      }
    })

    this._unreadedSupportSubscription = this._supportService.unreaded.subscribe(unreaded=>{
      this.isUnreadedSupport = unreaded;
    });

    this._supportService.getChatApi();

    this._firebaseRemoteConfigService.getString('donateMenuItemStyle').then(style=>{
      this.donateItemStyle = JSON.parse(style) ?? null;
    });

    this.openModalFromUrl();

  }

  private _initPlugins(){
    if(!Capacitor.isNativePlatform()){
      return;
    }
    if(Capacitor.isPluginAvailable('StatusBar')){
      StatusBar.setStyle({
        style: Style.Light
      });
    }
    setTimeout(() => {
      if(Capacitor.isPluginAvailable('SplashScreen')){
        SplashScreen.hide();
      }
      if(Capacitor.isPluginAvailable('StatusBar') && Capacitor.getPlatform()=='android'){
        StatusBar.setBackgroundColor({color:"#ffffff"});
      }
    }, 1000);
  }

  ngOnDestroy() {
    if(this._unreadedSupportSubscription){
      this._unreadedSupportSubscription.unsubscribe();
    }

    if(this._urlModalsSubscription) {
      this._urlModalsSubscription.unsubscribe();
    }
  }

  toggleMenu(){
    this._menuController.toggle();
    this._firebaseAnalyticsService.logEvent('toggleMainMenu');
  }

  // share(){
  //   const url = 'https://medinaschool.org';
  //   Share.share({
  //     title: 'Академия Медина',
  //     text: 'Академия Медина - получай базовое исламское образование в новом приложении!',
  //     url: url,
  //     dialogTitle: 'Пригласить друзей'
  //   }).then(shareResult=>{
  //     this._firebaseAnalyticsService.logEvent('share',{
  //       content: 'app',
  //       type: shareResult.activityType
  //     });
  //   }).catch(()=>{
  //     Clipboard.write({string:url}).then(() =>  {
  //       this._toastService.show({message:'Ссылка скопирована в буфер обмена'});
  //       this._firebaseAnalyticsService.logEvent('share',{
  //         content: 'app',
  //         type: 'clipboard'
  //       });
  //     }, () => {
  //       this._firebaseAnalyticsService.logEvent('share_error');
  //       this._toastService.showError('Не удалось вызвать функцию!');
  //     });
  //   })
  // }
  closeModal() {
    this._modalController.dismiss();
  }

  async openShareModal() {
    this.closeModal();
    const modal = await this._modalController.create({
      component: ShareModalComponent,
      cssClass: 'modal_share'
    });

    return await modal.present();
  }

  onArabicClick(){
    this._firebaseAnalyticsService.logEvent('openArabic');
    this._firebaseAnalyticsService.logEvent('openArabicFromMenu');
  }

  onAboutProjectClick(){
    this._firebaseAnalyticsService.logEvent('openAboutProject');
    this._firebaseAnalyticsService.logEvent('openAboutProjectFromMenu');
  }

  onDonateClick(){
    this._firebaseAnalyticsService.logEvent('openDonate');
    this._firebaseAnalyticsService.logEvent('openDonateFromMenu');
    this.openDonateModal();
  }

  async openDonateModal() {
    const accountData = this.accountService.accountData?.value;

    if (accountData.isDonateModal) {
      const modal = await this._modalController.create({
        component: DonateModalComponent,
        breakpoints: [0, 0.3, 0.5, 0.9, 1, 3],
        initialBreakpoint: 0.9,
        cssClass: 'sheet_modal'
      });

      return await modal.present();
    } else {
      Browser.open({url: accountData.donationLink, presentationStyle: 'popover'});
    }
  }

  getSupport(){
    this._supportService.openChatWithLoader();
  }
}
