import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

export interface Palm{
  id?: number,
  dead: boolean,
  finished: boolean,
  justCreated?: boolean,
  progress: number,
  skin: number,
  wilted: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class PalmService {
  palm: BehaviorSubject<Palm> = new BehaviorSubject<Palm>(null);

  constructor(
    private _storage: StorageService,
  ) {
    this._storage.get('palm', true).then(storedPalm=>{
      if(<Palm>storedPalm){
        this.palm.next(<Palm>storedPalm);
      }
      this.palm.subscribe(newPalm=>{
        if(!newPalm){
          this._storage.remove('palm');
          return;
        }
        this._storage.set('palm', newPalm);
      });
    });
  }
}
