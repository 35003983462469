import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'stringTrim'})
export class StringTrimPipe implements PipeTransform {
  transform(value: string, length?: number): string {
    if(!length){
      length = 16;
    }
    if(value.length<=length){
      return value;
    }

    return value.slice(0,length) + '...';
  }
}
