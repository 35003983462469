<div class="shareModelMainContainer">

  <section class="modalHeaderSection">

    <div class="modalHeader">
      <ion-icon (click)="closeModal()" name="close-outline"></ion-icon>
    </div>

    <ion-text class="title">
      {{title}}
    </ion-text>

  </section>

  <ion-text class="description">
    “Указавшему на благое полагается <br> такая же награда как и <br> совершившему его”
  </ion-text>

  <section>

    <ion-text class="descriptionForDescription">Этот хадис передал Муслим (1893)</ion-text>

    <div class="buttonsContainer">

      <a href="https://t.me/share/url?url={{shareLink}}&text={{shareText}}"
         target="_blank"
         class="shareButton">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Group 682">
            <rect id="Rectangle 92" x="0.8" y="0.8" width="46.4" height="46.4" rx="7.2" stroke="#00CB9F"
                  stroke-width="1.6"/>
            <path id="Vector"
                  d="M20.161 33.6911C20.2808 31.9832 20.4092 30.2753 20.5128 28.5662C20.5302 28.2805 20.6936 28.1347 20.8782 27.973C24.2482 25.0082 27.6182 22.0421 30.987 19.0761C31.2065 18.8828 31.426 18.6907 31.6381 18.4889C31.7229 18.4087 31.8364 18.3187 31.7528 18.1789C31.6767 18.0513 31.5383 18.0732 31.4161 18.0817C31.1417 18.1011 30.9222 18.2531 30.6989 18.3905C27.5821 20.3014 24.4677 22.2135 21.3521 24.1256C20.0775 24.9085 18.8003 25.6877 17.5306 26.479C17.3024 26.6213 17.1066 26.6541 16.8384 26.5702C15.0212 26.0037 13.1953 25.4616 11.3755 24.9024C10.9465 24.7699 10.4326 24.641 10.3827 24.1293C10.3304 23.5896 10.8243 23.3477 11.2521 23.1811C13.1977 22.4238 15.1534 21.6932 17.1078 20.9578C22.8401 18.8001 28.5736 16.6449 34.3071 14.4908C34.4955 14.4203 34.6863 14.351 34.8821 14.3073C35.608 14.1468 36.1855 14.537 36.2977 15.253C36.3526 15.6031 36.3189 15.9483 36.2441 16.2911C34.952 22.2269 33.6598 28.1626 32.3664 34.0984C32.0459 35.5705 31.1591 35.8999 29.9144 35.0064C28.1234 33.7227 26.3311 32.4427 24.5513 31.1457C24.2894 30.9548 24.1472 30.9475 23.9065 31.1834C23.0521 32.0221 22.1666 32.8305 21.2948 33.6522C21.1264 33.8103 20.9568 33.9671 20.7422 34.0643C20.2259 34.3002 20.1236 34.2333 20.1635 33.6911H20.161Z"
                  fill="#00CB9F"/>
          </g>
        </svg>
      </a>

      <a href="https://vk.com/share.php?url={{shareLink}}&title={{shareText}}"
         target="_blank"
         class="shareButton">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.8" y="0.8" width="46.4" height="46.4" rx="7.2" stroke="#00CB9F" stroke-width="1.6"/>
          <path
            d="M25.9237 32.4332C16.1725 32.4332 10.6107 26.1026 10.3789 15.5684H15.2634C15.4238 23.3002 19.0248 26.5752 21.877 27.2505V15.5684H26.4765V22.2366C29.2931 21.9496 32.2519 18.9109 33.2502 15.5684H37.8497C37.0831 19.6875 33.8743 22.7262 31.5925 23.9754C33.8743 24.9883 37.529 27.6388 38.9194 32.4332H33.8565C32.7691 29.2257 30.0596 26.7441 26.4765 26.4064V32.4332H25.9237Z"
            fill="#00CB9F"/>
        </svg>
      </a>

      <a href="mailto:?subject={{shareText}}&amp;body={{shareLink}} {{shareText}}"
         target="_blank"
         class="shareButton">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.8" y="0.8" width="46.4" height="46.4" rx="7.2" stroke="#00CB9F" stroke-width="1.6"/>
          <g clip-path="url(#clip0_543_363)">
            <path
              d="M37 31.1771C36.8956 31.3615 36.8988 31.5874 36.7607 31.7731C36.7092 31.7259 36.659 31.6824 36.6119 31.6359C34.0919 29.1595 31.5732 26.683 29.0513 24.2085C28.9426 24.1015 28.8827 24.0411 29.0278 23.8989C31.5764 21.4042 34.1186 18.9026 36.6621 16.4029C36.6914 16.374 36.7238 16.3482 36.7709 16.3066C36.8619 16.5074 36.9459 16.6924 36.9548 16.9026C37.0031 16.9838 36.9382 17.0839 36.9993 17.1625V31.1771H37Z"
              fill="#00CB9F"/>
            <path
              d="M36.9999 17.1623C36.9281 17.0855 36.9414 16.9936 36.9554 16.9023C36.9707 16.903 36.9853 16.9061 36.9999 16.9112V17.1623Z"
              fill="#454545"/>
            <path
              d="M12.3213 15.2303C12.6572 15.0648 12.9982 15.0013 13.3557 15.0019C14.4646 15.0031 15.5729 15.0019 16.6818 15.0019C22.6397 15.0019 28.5971 15.0025 34.555 15C34.9412 15 35.3121 15.051 35.6626 15.219C35.6474 15.3008 35.5767 15.3405 35.5246 15.3914C32.2558 18.6105 28.9864 21.8282 25.7164 25.046C25.014 25.737 24.1959 25.9793 23.2365 25.669C22.8834 25.5551 22.5895 25.3456 22.3299 25.0901C19.0293 21.8446 15.73 18.5966 12.4307 15.3493C12.3957 15.3147 12.3639 15.2769 12.3213 15.2303Z"
              fill="#00CB9F"/>
            <path
              d="M35.6678 32.8634C35.3173 33.0365 34.9483 33.0875 34.5647 33.0869C30.7157 33.0843 26.8667 33.0856 23.0177 33.0856C19.8202 33.0856 16.6227 33.0843 13.4246 33.0875C13.0473 33.0875 12.6853 33.0327 12.3398 32.8697C12.3602 32.7716 12.4474 32.7256 12.5097 32.6639C15.0049 30.2089 17.5026 27.7571 19.9952 25.2995C20.1428 25.1541 20.2198 25.1208 20.3839 25.2901C20.793 25.7124 21.1893 26.1523 21.6582 26.5122C23.2073 27.701 25.2959 27.5594 26.7344 26.1737C27.0557 25.864 27.3719 25.55 27.6849 25.2322C27.7707 25.1453 27.8223 25.1176 27.9253 25.2196C30.4905 27.747 33.0594 30.2706 35.6271 32.7961C35.6443 32.8131 35.6532 32.8376 35.6691 32.8634H35.6678Z"
              fill="#00CB9F"/>
            <path
              d="M11.2297 31.7837C11.0687 31.4495 11 31.1178 11 30.7698C11.0006 26.2852 11.0006 21.8006 11 17.316C11 16.968 11.0693 16.6376 11.2214 16.3223C11.3111 16.3468 11.3569 16.4223 11.4154 16.4802C13.9182 18.9384 16.4185 21.3997 18.9263 23.8528C19.086 24.0095 19.0829 24.0813 18.9251 24.2348C16.4178 26.6886 13.9176 29.1493 11.4154 31.6087C11.362 31.6616 11.306 31.7119 11.2297 31.7837Z"
              fill="#00CB9F"/>
          </g>
          <defs>
            <clipPath id="clip0_543_363">
              <rect width="26" height="18.087" fill="white" transform="translate(11 15)"/>
            </clipPath>
          </defs>
        </svg>
      </a>

    </div>

    <div class="copyContainer">
      <input value="{{shareLink}}" disabled>
      <ion-button (click)="copyShareLink()"  expand="block" color="primary" size="default" fill="clear">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_543_380)">
            <path d="M19.9966 3.49447C19.9891 1.53209 18.4727 0.00843697 16.5149 0.00468721C13.5058 -0.0015624 10.4973 -0.0015624 7.48818 0.00468721C5.52606 0.00906193 4.01525 1.52897 4.00337 3.49134C4.00129 3.83007 3.82794 4.00048 3.48331 4.00256C1.53807 4.01506 0.0103748 5.52684 0.00537417 7.46485C-0.00212677 10.494 -0.00275185 13.5238 0.00537417 16.553C0.0109999 18.4542 1.53994 19.9872 3.4408 19.9934C6.48118 20.0034 9.52094 20.0022 12.5613 19.9934C14.184 19.9891 15.5736 18.8435 15.9005 17.2492C15.943 17.0417 16.0143 16.9618 16.2193 16.9961C16.2899 17.008 16.3649 16.998 16.4374 16.998C18.4952 16.993 19.9941 15.4912 19.9966 13.4263C19.9991 11.7815 19.9966 10.1366 19.9966 8.49166C19.9966 6.82614 20.0016 5.16061 19.9954 3.49447H19.9966ZM12.4282 17.9917C9.47218 17.9936 6.51681 17.9936 3.56082 17.9917C2.6032 17.9911 2.00875 17.3924 2.0075 16.4305C2.005 14.9425 2.00687 13.4545 2.00687 11.9664C2.00687 10.5097 2.00562 9.05287 2.00687 7.59546C2.00812 6.59365 2.59632 5.99994 3.59145 5.99994C6.53682 5.99869 9.48218 5.99869 12.4276 5.99994C13.3989 5.99994 14.0002 6.6049 14.0002 7.57984C14.0015 10.5247 14.0015 13.4701 14.0002 16.4149C14.0002 17.393 13.4039 17.9911 12.4282 17.9917ZM16.1336 14.9975C15.933 14.9769 16.0005 14.845 16.0005 14.75C15.9967 13.4388 15.998 12.1271 15.998 10.8159C15.998 9.72283 16.0005 8.6304 15.998 7.53734C15.9924 5.51122 14.4909 4.00694 12.4676 4.00381C10.4067 4.00069 8.34579 3.99881 6.28428 4.00881C6.05175 4.01006 5.99175 3.95507 5.99425 3.72008C6.00487 2.77951 6.42555 2.16518 7.1894 2.03206C7.32129 2.00894 7.45881 2.00831 7.59382 2.00769C10.5292 2.00644 13.4646 2.00581 16.3999 2.00769C17.405 2.00769 17.9932 2.59203 17.9945 3.59071C17.9964 5.23499 17.9945 6.87988 17.9945 8.52416C17.9945 10.1684 17.9714 11.7721 18.0039 13.3951C18.0208 14.2407 17.4676 15.1337 16.1336 14.9988V14.9975Z" fill="#00CB9F"/>
          </g>
          <defs>
            <clipPath id="clip0_543_380">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>

      </ion-button>
    </div>
  </section>
</div>
