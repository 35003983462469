import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {NotAuthGuard} from './auth/not-auth.guard';
import {ExamExitGuard} from './exam/exam-exit.guard';
import { UserNecessaryParametersGuard } from './auth/user-necessary-parameters.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule),
    canActivate: [NotAuthGuard],
    canLoad: [NotAuthGuard],
    canDeactivate: [NotAuthGuard],
  },
  {
    path: 'school',
    loadChildren: () => import('./school/school.module').then(m => m.SchoolPageModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountPageModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'exam',
    canDeactivate: [ExamExitGuard],
    loadChildren: () => import('./exam/exam.module').then(m => m.ExamPageModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'webinars',
    loadChildren: () => import('./webinars/webinars.module').then(m => m.WebinarsPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'page/:id',
    loadChildren: () => import('./custom-page/custom-page.module').then(m => m.CustomPagePageModule)
  },
  {
    path: 'course-certificate/:id',
    loadChildren: () =>
        import('./shared/course-certificates/course-certificate/course-certificate.module')
            .then(m => m.CourseCertificatePageModule)
  },
  {
    path: 'course-certificate-order/:level/:subject',
    loadChildren: () =>
        import('./shared/course-certificates/course-certificate-order/course-certificate-order.module')
            .then(m => m.CourseCertificateOrderPageModule)
  },
  {
    path: 'course-certificate-purchase/:id',
    loadChildren: () =>
        import('./shared/course-certificates/course-certificate-purchase/course-certificate-purchase.module')
            .then( m => m.CourseCertificatePurchasePageModule)
  },
  {
    path: 'organization-contacts',
    loadChildren: () =>
        import('./organization-contacts/organization-contacts.module')
            .then( m => m.OrganizationContactsPageModule)
  },
  {
    path: 'exam-congratulations',
    loadChildren: () =>
        import('./exam-congratulations/exam-congratulations.module')
            .then(m => m.ExamCongratulationsPageModule)
  },
  {
    path: 'home',
    // component: HomePage,
    canActivate: [AuthGuard, UserNecessaryParametersGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'map',
    canActivate: [AuthGuard, UserNecessaryParametersGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./map/map.module').then(m => m.MapPageModule)
  },
  {
    path: 'play',
    canActivate: [AuthGuard, UserNecessaryParametersGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./play/play.module').then( m => m.PlayPageModule)
  },
  {
    path: 'course',
    canActivate: [AuthGuard, UserNecessaryParametersGuard],
    loadChildren: () => import('./course/course.module').then(m => m.CoursePageModule)
  },
  {
    path: 'mazhab-howto',
    loadChildren: () => import('./mazhab-howto/mazhab-howto.module').then(m => m.MazhabHowtoPageModule)
  },
  {
    path: 'practice-schedule',
    canActivate: [AuthGuard],
    loadChildren: () => import('./practice-schedule/practice-schedule.module').then(m => m.PracticeSchedulePageModule)
  },
  {
    path: 'entry-exam',
    canActivate: [AuthGuard],
    loadChildren: () => import('./entry-exam/entry-exam.module').then(m => m.EntryExamPageModule),
  },
  {
    path: 'certificate',
    canActivate: [AuthGuard],
    loadChildren: () => import('./certificate/certificate.module').then(m => m.CertificatePageModule)
  },
  {
    path: 'courses',
    canActivate: [AuthGuard],
    loadChildren: () => import('./courses/courses.module').then( m => m.CoursesPageModule)
  },
  {
    path: 'courses-category',
    canActivate: [AuthGuard],
    loadChildren: () => import('./courses-category/courses-category.module').then( m => m.CoursesCategoryPageModule)
  },
  {
    path: 'courses-recommendation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./courses-recommendation/courses-recommendation.module').then( m => m.CoursesRecommendationPageModule)
  },
  {
    path: 'courses-new-list',
    loadChildren: () => import('./courses-new-list/courses-new-list.module').then( m => m.CoursesNewListPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
