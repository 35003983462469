import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Palm, PalmService } from '../services/palm.service';

@Component({
  selector: 'app-palm-progress',
  templateUrl: './palm-progress.component.html',
  styleUrls: ['./palm-progress.component.scss'],
})
export class PalmProgressComponent implements OnInit, OnDestroy {

  @Input('size') size: number;
  @Input('stroke') stroke: number;
  @Input('disabled') disabled: boolean = false;
  radius: number;
  circumference: number;
  strokeDashoffset: number;
  private _progress: number = 0;
  get imageSrc(): string{
    if(!this.palm){
      return null;
    }
    return `./assets/palms/${this.palm.skin}/${this.palm.progress}${this.palm.wilted?'w':''}.svg`;
  }
  get baseImgSrc(): string{
    if(!this.palm){
      return null;
    }
    return `./assets/palms/base${this.palm.wilted?'w':''}.svg`;
  }
  imgStyle: {[key: string]: any};
  @Input('palm') palm: Palm = null;
  private _palmSubscribtion: Subscription;

  set progress(value: number){
    this._progress = value;
    this.strokeDashoffset = this.circumference - value / 100 * this.circumference;
  }

  get progress():number{
    return this._progress;
  }

  constructor(
    private _palmService: PalmService
  ) { }

  ngOnInit() {
    if(!this.size){
      this.size = 100;
    }
    if(!this.stroke){
      this.stroke = 4;
    }

    const imgSize = this.size - this.stroke*2;
    this.imgStyle = {
      'left.px':this.stroke,
      'top.px':this.stroke,
      'width.px':imgSize,
      'height.px':imgSize,
  };

    this.radius = this.size/2 - this.stroke/2;
    this.circumference = this.radius * 2 * Math.PI;
    this.strokeDashoffset = this.circumference;
    if(this.palm){
      this._updateProgress();
    }
    if(!this.palm){
      this._palmSubscribtion = this._palmService.palm.subscribe(newPalm=>{
        this.palm = newPalm;
        this._updateProgress();
      })
    }
  }

  ngOnDestroy(){
    if(this._palmSubscribtion){
      this._palmSubscribtion.unsubscribe();
    }
  }

  private _updateProgress(){
    if(!this.palm){
      this.progress = 0;
      return;
    }
    const maxProgress = 4;
    this.progress = ( 100/(maxProgress+1) ) * (this.palm.progress+1);
  }

  setRandomProgress(){
    const progress = this.getRandomInt(0,101);
    this.progress = progress;
  }

  getRandomInt(min, max):number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
  }

}
