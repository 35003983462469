<div class="img-content" [class.disabled]="disabled" (click)="onClick()">
  <img [src]="imageSrc" [ngStyle]="imgStyle">
  <svg
    class="progress-ring"
    [attr.width]="size"
    [attr.height]="size">
    <circle
      [attr.stroke]="unfilledColor"
      [attr.stroke-width]="stroke"
      fill="transparent"
      [attr.r]="radius"
      [attr.cx]="size/2"
      [attr.cy]="size/2"/>
    <circle
      class="progress"
      [attr.stroke]="filledColor"
      [attr.stroke-width]="stroke"
      fill="transparent"
      [attr.r]="radius"
      [attr.cx]="size/2"
      [attr.cy]="size/2"
      [ngStyle]="{strokeDasharray:circumference+' '+circumference, strokeDashoffset:strokeDashoffset}"/>
  </svg>
</div>
