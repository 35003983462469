import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { PlatformService } from '../shared/services/platform.service';
import { ExamStateService } from './exam-state.service';

@Injectable({
  providedIn: 'root'
})
export class ExamExitGuard implements CanDeactivate<unknown> {
  constructor(
    private _examStateService: ExamStateService,
    private _platformService: PlatformService,
    private _alertController: AlertController
    ){}

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this._examStateService.examStarted){
        return true;
      }
      return this._alertController.create({
        header: 'Экзамен продолжается!',
        message: 'Если Вы прервете экзамен сейчас, пересдача будет доступна не ранее указанного срока!',
        mode: this._platformService.nativeMode,
        buttons: [
          {
            text: 'Прервать экзамен',
            role: 'destructive',
            cssClass: 'secondary'
          },
          {
            text: 'Продолжить',
            role: 'cancel'
          }
        ]
      }).then(alert=>{
        alert.present();
        return alert.onDidDismiss();
      }).then(answer=>{
        if(answer.role == 'destructive'){
          this._examStateService.examStarted = false;
          return true;
        }else{
          return false;
        }
      });
  }

}
