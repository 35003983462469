import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dot',
  template: '',
  styleUrls: ['./dot.component.scss'],
})
export class DotComponent implements OnInit {
  @Input('color') color: string;
  @HostBinding('style.--dot-color') get IonColorName(){
    const color = this.color ?? 'primary';
    return `var(--ion-color-${color})`;
  }

  constructor() { }

  ngOnInit() {}

}
