import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController, LoadingController, NavController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { PlatformService } from '../shared/services/platform.service';
import { StorageService } from '../shared/services/storage.service';

import { SignInWithApple } from '@capacitor-community/apple-sign-in';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';
import { AccountService } from '../account/account.service';
import { ToastService } from '../shared/services/toast.service';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FirebaseAnalyticsService } from '../shared/services/firebase-analytics.service';
import { PushService } from '../shared/services/push.service';
import { AccountData } from '../shared/account-data.model';
import { AmplitudeEventsEnum, AmplitudePropertiesEnum, AmplitudeService } from '../shared/services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _id: number = null;
  get id() {
    return this._id;
  }

  private _token: string = null;
  get token() {
    return this._token;
  }

  isAuthLoading$ = new BehaviorSubject(false);

  private _storageInitialized = false;

  lastEmail: string = null;

  minPasswordLength = environment.minPasswordLength;

  constructor(
    private _navController: NavController,
    private _storage: StorageService,
    private _platformService: PlatformService,
    private _loadingController: LoadingController,
    private _httpClient: HttpClient,
    private _alertController: AlertController,
    private _accountService: AccountService,
    private _toastService: ToastService,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private _pushService: PushService,
    private amplitudeAnalytics: AmplitudeService,
    private platform: Platform,
  ) {
    this.platform.ready().then(() => {
      GoogleAuth.initialize();
    });
  }

  isAuthenticated(): Promise<boolean> {
    if (this._storageInitialized) {
      return of(!!this._id && !!this._token).toPromise();
    }
    return Promise.all([
      this._storage.get('userId'),
      this._storage.get('userToken')
    ]).then(values => {
      this._id = values[0];
      this._token = values[1];
      this._storageInitialized = true;
      const result = (!!this._id && !!this._token);
      if (result) {
        this._firebaseAnalyticsService.setUserId(this._id);
        this._accountService.fetchAccountData().subscribe();
        this._pushService.bindDevice();
      }
      return of(result).toPromise();
    });
  }

  isAuthenticatedObservable(): Observable<boolean> {
    if (this._storageInitialized) {
      return of(!!this._id && !!this._token);
    }
    // this._storage.get('userId');
    // this._storage.get('userToken');

    return of(true);
  }

  async setAuthenticated(id: number, token: string) {
    this._id = id;
    this._token = token;
    if (!id || !token) {
      return;
    }
    await Promise.all([
      this._storage.set('userId', id.toString()),
      this._storage.set('userToken', token),
      this._firebaseAnalyticsService.setUserId(id),
      this._pushService.bindDevice(),
    ]).then(() => {
      this.navigateMain();
    });
  }

  async signInWith(provider: 'Google' | 'Apple') {
    let signInPromise: Promise<any>;
    switch (provider) {
      case 'Apple':
        signInPromise = SignInWithApple.authorize(environment.signInWithAppleOptions);
        break;
      case 'Google':
        signInPromise = GoogleAuth.signIn();
        break;
      default:
    }
    const loading = await this._loadingController.create({
      message: 'Авторизация...',
      mode: this._platformService.nativeMode
    });
    signInPromise.then(signInResult => {
      if (!signInResult) {
        return;
      }
      loading.present();
      return this._httpClient.post<{
        id: number,
        token: string,
        accountData: AccountData
      }>(`${environment.apiUrl}/signInWith`, signInResult, {
        params: {
          provider: provider
        }
      }).pipe(
        catchError(error => {
          this._firebaseAnalyticsService.logEvent('login_failed', {
            method: provider,
            stage: 'backend',
            errorMessage: error
          });
          this._showError(error);
          loading.dismiss();
          return throwError(EMPTY);
        })
      )
        .toPromise();
    }).then(response => {
      this._firebaseAnalyticsService.logEvent('login', {method: provider});

      this.amplitudeAnalytics.trackEvent({
        eventInput: AmplitudeEventsEnum.authSuccess,
        eventProperties: {
          [AmplitudePropertiesEnum.typeAuth]: provider,
        }
      });

      return this.setAuthenticated(+response.id, response.token);
    }).catch(error => {
      if (Object.keys(error).length === 0) {
        return;
      }
      if (error.error) {
        switch (error.error) {
          case 'popup_closed_by_user':
            this._firebaseAnalyticsService.logEvent('login_cancel', {
              method: provider,
            });
            return;
        }
      }
      if (error.errorMessage) {
        switch (error.errorMessage) {
          case 'The user canceled the sign-in flow.':
            this._firebaseAnalyticsService.logEvent('login_cancel', {
              method: provider,
            });
            return;
        }
        if (error.errorMessage.includes('1001')) {
          this._firebaseAnalyticsService.logEvent('login_cancel', {
            method: provider,
          });
          return;
        }
      }
      if (error.errorMessage || error.error) {
        const errorMessage = error.errorMessage ?? error.error;
        this._firebaseAnalyticsService.logEvent('login_failed', {
          method: provider,
          stage: 'provider',
          errorMessage: errorMessage
        });
        this._toastService.showError(errorMessage);
      }
    }).finally(() => {
      loading.dismiss();
    });
  }

  logout(): Promise<any> {
    return Promise.all([
      this._pushService.unbindDevice(),
      GoogleAuth.signOut(),
      this._storage.clear(),
      this._firebaseAnalyticsService.logEvent('logout'),
    ]).then(() => {
      return Promise.all([
        this._firebaseAnalyticsService.reset(),
        this.setAuthenticated(null, null)
      ]);
    }).then(() => {
      window.location.assign('/'); // completely reload page
    }).catch(error => {
      this._toastService.showError('Ошибка! Не удалось выйти из аккаунта.');
    });
  }

  navigateAuth(): Promise<boolean> {
    return this._navController.navigateRoot('/auth', {animationDirection: 'back'});
  }

  navigateMain() {
    this._navController.navigateRoot('/home', {animationDirection: 'forward'});
  }

  loginWithPassword(mail: string, password: string): Observable<any> {
    this.isAuthLoading$.next(true);
    return this._httpClient.post<{
      result: string,
      id: number,
      token: string,
      accountData: AccountData,
      reason?: string
    }>(`${environment.apiUrl}/login`, {
      mail: mail,
      password: password
    }).pipe(
      catchError(error => {
        // console.log(error);
        this._firebaseAnalyticsService.logEvent('login_failed', {
          method: 'password',
          errorMessage: error.error.reason ?? 'unknown'
        });
        this.isAuthLoading$.next(false);
        return of(error);
      }),
      tap(response => {
        if (!response.result || response.result !== 'success') {
          this.isAuthLoading$.next(false);
          return throwError({error: response});
        }
        this._firebaseAnalyticsService.logEvent('login', {method: 'password'});

        this.amplitudeAnalytics.trackEvent({
          eventInput: AmplitudeEventsEnum.authSuccess,
          eventProperties: {
            [AmplitudePropertiesEnum.typeAuth]: 'Email',
          }
        });
      }),
      tap(response => {
        this.setAuthenticated(+response.id, response.token);
        this.isAuthLoading$.next(false);
      }),
    );
  }

  checkEmail(mail: string): Promise<any> {
    return this._httpClient.post<{
      result: string,
      status?: string,
      reason?: string
    }>(`${environment.apiUrl}/checkEmail`, {mail: mail}).pipe(
      catchError(error => {
        this._firebaseAnalyticsService.logEvent('login_step1_failed', {
          method: 'password',
          errorMessage: error.error.reason ?? 'unknown'
        });
        return throwError(error);
      })
    ).toPromise().then(response => {
      if (!response.result || response.result !== 'success' || !response.status) {
        return throwError({error: response});
      }
      this._firebaseAnalyticsService.logEvent('login_step1', {answer: response.status ?? 'unknown'});
      return response.status;
    });
  }

  register(formValues: any): Observable<any> {
    formValues.mail = formValues.email;

    this.isAuthLoading$.next(true);

    return this._httpClient.post<{
      result: string,
      reason?: string,
      field?: string
    }>(`${environment.apiUrl}/register`, formValues).pipe(
      catchError(error => {
        // console.log(error);
        this._showError(error, formValues);
        this.isAuthLoading$.next(false);
        return of(error);
      }),
      switchMap((response: { result: string, reason?: string, field?: string }) => {
        if (!response.result || response.result !== 'success') {
          this.isAuthLoading$.next(false);
          return throwError({error: response});
        }
        this._firebaseAnalyticsService.logEvent('sign_up');
        return this.loginWithPassword(formValues.mail, formValues.password);
      }),
      tap(() => this.isAuthLoading$.next(false))
    );
  }

  restore(form: NgForm): Promise<any> {
    return this._httpClient.post<{
      result: string,
      reason?: string,
      field?: string
    }>(`${environment.apiUrl}/restore`, form.value).pipe(
      catchError(error => {
        this._firebaseAnalyticsService.logEvent('restore_failed', {
          errorMessage: error.error.reason ?? 'unknown'
        });
        this._showError(error, form);
        return EMPTY;
      }),
      tap(response => {
        if (!response.result || response.result !== 'success') {
          return throwError({error: response});
        }
        this._firebaseAnalyticsService.logEvent('restore_form_sent');
      })
    ).toPromise();
  }

  restorePassword(mail: string): Observable<any> {
    this.isAuthLoading$.next(true);
    return this._httpClient.post<any>(`${environment.apiUrl}/restorePassword`, {mail: mail})
        .pipe(
          tap(() => {
            this._firebaseAnalyticsService.logEvent('restorePassword');
            this.isAuthLoading$.next(false);
          }),
          catchError(error => {
            this._firebaseAnalyticsService.logEvent('restorePassword_failed', {errorMessage: error.error.reason ?? 'unknown'});
            this._showError(error);
            this.isAuthLoading$.next(false);
            return throwError(error);
          })
        );

  }

  private _showError(error?: any, form?: any) {
    // if (form && error && error.error && error.error.field){
    //   form.controls[error.error.field].setErrors({ incorrect: true});
    // }

    if (error.error && !error.error?.reason) {
      this._alertController.create({
        mode: this._platformService.nativeMode,
        header: `Ошибка!`,
        message: JSON.stringify(error.error),
        buttons: ['OK']
      }).then(alert => alert.present());

      return;
    }

    let message: string;
    switch (error.error?.reason) {
      case 'wrongMail':
        message = 'E-mail введен неверно, либо Вы еще не зарегистрированы!';
        break;
      case 'wrongPassword':
        message = 'Неверный пароль!';
        break;
      case 'wrongNameLength':
        message = 'Слишком короткое имя!';
        break;
      case 'fakeMail':
        message = 'Пожалуйста, введите настоящий корректный адрес электронной почты!';
        break;
      case 'shortPassword':
        message = `Минимальная длина пароля – ${this.minPasswordLength} символов!`;
        break;
      case 'mailExists':
        this._alertController.create({
          mode: this._platformService.nativeMode,
          header: `${form.email} уже зарегистрирован!`,
          message: `Если Вы забыли пароль, воспользуйтесь функцией восстановления пароля.`,
          buttons: [
            {
              text: 'Войти с этим E-mail',
              handler: () => {
                this.lastEmail = form.email;
                this._navController.navigateBack('/auth/login');
              }
            },
            {
              text: 'Восстановить пароль',
              handler: () => {
                this.lastEmail = form.email;
                this._navController.navigateBack('/auth/restore-password');
              }
            },
            {
              text: 'Отмена',
              role: 'cancel'
            }
          ]
        }).then(alert => {
          alert.present();
        });
        break;
      default:
        message = 'Неизвестная ошибка! Пожалуйста, проверьте введенные данные или попытайтесь позже.';
    }
    if (!message) {
      return;
    }
    this._toastService.showError(message);
  }

}
