import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  private _token: string;
  private _registrationPromise: Promise<any>;
  private _bindOnRegister: boolean = false;

  get isPluginAvailable(): boolean{
    return Capacitor.isPluginAvailable('PushNotifications');
  }

  constructor(
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private _navController: NavController,
    private _httpClient: HttpClient,
    private _storageService: StorageService
  ) {
    if(!this.isPluginAvailable){
      return;
    }

    PushNotifications.removeAllListeners()
      .then(()=>this._registerListeners())
      .then(()=>this._register());
  }

  private _registerListeners(): Promise<any>{
    return Promise.all([
      PushNotifications.addListener('registration',
        (token: Token) => {
          this._token = token.value;
          if(this._bindOnRegister){
            this.bindDevice();
          }

        }
      ),

      PushNotifications.addListener('registrationError',
        (error: any) => {
          this._firebaseAnalyticsService.logEvent('push_registrationError',error);
        }
      ),

      PushNotifications.addListener('pushNotificationActionPerformed',
        (actionPerformed: ActionPerformed) => {
          const firebaseLogData = {
            id: actionPerformed.notification.id,
            title: actionPerformed.notification.title,
            label: actionPerformed.notification.data["google.c.a.c_l"] ?? null,
            campaign: actionPerformed.notification.data["google.c.a.c_id"] ?? null,
            routerLink: actionPerformed.notification.data["routerLink"] ?? null,
            externalLink: actionPerformed.notification.data["externalLink"] ?? null,
          };
          this._firebaseAnalyticsService.logEvent(`push_${actionPerformed.actionId}`,firebaseLogData);

          if(actionPerformed.actionId!='tap'){
            return;
          }

          if(actionPerformed.notification.data.routerLink){
            this._navController.navigateForward(<string>actionPerformed.notification.data.routerLink);
          }
          if(actionPerformed.notification.data.externalLink){
            window.open(<string> actionPerformed.notification.data.externalLink, '_system');
          }
        }
      ),
    ])
  }

  private _register(): Promise<any>{
    if(!this.isPluginAvailable){
      return;
    }
    if(this._token){
      return Promise.resolve();
    }
    if(this._registrationPromise){
      return this._registrationPromise;
    }

    this._registrationPromise = PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        return PushNotifications.register();
      } else {
        this._firebaseAnalyticsService.logEvent('push_permissionsRefused');
        return Promise.reject();
      }
    }).finally(()=>{
      this._registrationPromise = null;
    });

    return this._registrationPromise;
  }

  getDeviceId(){
    if(!this.isPluginAvailable){
      return null;
    }
    return this._token;
  }

  bindDevice(): Promise<any>{
    if(!this.isPluginAvailable){
      return Promise.resolve();
    }
    this._bindOnRegister = false;
    if(!this._token){
      this._bindOnRegister = true;
      return Promise.resolve();
    }
    return this._storageService.get('bindedFCMToken').then(bindedToken=>{
      if(this._token==bindedToken){
        return;
      }
      return this._httpClient.post<{result:string}>(`${environment.apiUrl}/bindDevice`,{token: this._token}).toPromise().then(response=>{
        this._storageService.set('bindedFCMToken',this._token);
      });
    });
  }

  unbindDevice(): Promise<any>{
    if(!this.isPluginAvailable){
      return Promise.resolve();
    }
    this._bindOnRegister = false;
    return this.resetNotifications().then(()=>{
      return this._storageService.get('bindedFCMToken');
    }).then(bindedToken=>{
      const token = bindedToken ?? this._token;
      if(!token){
        return;
      }
      return this._httpClient.post<{result:string}>(`${environment.apiUrl}/unbindDevice`,{token: token}).toPromise();
    }).then(response=>{
      return this._storageService.remove('bindedFCMToken');
    });
  }

  resetNotifications(): Promise<void>{
    return PushNotifications.removeAllDeliveredNotifications();
  }
}
