import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  public async set(key:string, value:string|object|any[]) {
    return await Preferences.set({
      key: key,
      value: typeof (value) === 'string' ? value : JSON.stringify(value),
    });
  }

  public async get(key:string, jsonDecode?: boolean): Promise<any> {
    let result = (await Preferences.get({ key: key })).value;
    return (result && jsonDecode) ? JSON.parse(result) : result;
  }

  public async remove(key:string) {
    return await Preferences.remove({ key: key });
  };

  public async clear(){
    return await Preferences.clear();
  }

}
