import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {CourseCard} from '../../Models/courses-models/course-card.model';
import {CourseModel} from '../../Models/courses-models/course.model';
import { CoursesCategoryModel } from '../../Models/courses-models/courses-category.model';
import { CoursesRecommendationsModel } from '../../Models/courses-models/courses-recommendations.model';

@Injectable({
  providedIn: 'root'
})
export class CoursesHttpRequestsService {

  baseUrl = environment.api4Url + '/courses';

  constructor(private httpClient: HttpClient) { }

  getStartedSchoolCoursesForCurrentUser(): Observable<HttpResponse<CourseCard[]>> {
    return this.httpClient.get<CourseCard[]>(`${this.baseUrl}/getStartedSchoolCoursesForCurrentUser_new`,
      {
        observe: 'response',
      }
    ).pipe(
      // retry(0)
    );
  }

  getNotStartedSchoolCoursesForCurrentUser(): Observable<HttpResponse<CourseCard[]>> {
    return this.httpClient.get<CourseCard[]>(`${this.baseUrl}/getNotStartedSchoolCoursesForCurrentUser_new`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  getFinishedSchoolCoursesForCurrentUser(): Observable<HttpResponse<CourseCard[]>> {
    return this.httpClient.get<CourseCard[]>(`${this.baseUrl}/getFinishedSchoolCoursesForCurrentUser_new`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  getSchoolCourseById(id: number): Observable<HttpResponse<CourseModel>> {
    return this.httpClient.get<CourseModel>(`${this.baseUrl}/getSchoolCourseById?id=${id}`,
        {
          observe: 'response',
        }
    ).pipe(retry(0));
  }

  getSchoolCourseByUrl(url: string): Observable<HttpResponse<CourseModel>> {
    return this.httpClient.get<CourseModel>(`${this.baseUrl}/getSchoolCourseByUrl?id=${url}`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  getLessonDetailsByVideoId(videoId: number): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(`${this.baseUrl}/getLessonDetailsByVideoId?videoId=${videoId}`,
        {
          observe: 'response',
        }
    ).pipe(retry(0));
  }

  getAllCourses(): Observable<HttpResponse<CourseCard[]>> {
    return this.httpClient.get<CourseCard[]>(`${this.baseUrl}/getAllCourses`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  getAllCoursesCategories(): Observable<HttpResponse<CoursesCategoryModel[]>> {
    return this.httpClient.get<CoursesCategoryModel[]>(`${this.baseUrl}/getAllCoursesCategories`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  getAllCoursesRecommendations(): Observable<HttpResponse<CoursesRecommendationsModel[]>> {
    return this.httpClient.get<CoursesRecommendationsModel[]>(`${this.baseUrl}/getAllCoursesRecommendations`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  getNewCoursesIds(): Observable<HttpResponse<number[]>> {
    return this.httpClient.get<number[]>(`${this.baseUrl}/getNewCoursesIds`,
      {
        observe: 'response',
      }
    ).pipe(retry(0));
  }

  // getTest(): Observable<HttpResponse<CourseModel>> {
  //   return this.httpClient.get<CourseModel>(`${environment.api4Url}/test`,
  //     {
  //       observe: 'response',
  //     }
  //   ).pipe(retry(0));
  // }

}
