import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { ModalController, Platform } from '@ionic/angular';
import { AccountService } from '../../account/account.service';
import { DonatesHttpRequestsService } from '../Services/http-requests/donates-http-requests.service';
import { Browser } from '@capacitor/browser';
import { Clipboard } from '@capacitor/clipboard';
import { ToastService } from '../Services/toast.service';
import { SupportService } from '../Services/support.service';


@Component({
  selector: 'app-donate-modal',
  templateUrl: './donate-modal.component.html',
  styleUrls: ['./donate-modal.component.scss'],
})
export class DonateModalComponent implements OnDestroy {
  @Input() isAboutShow = true;

  donateAmounts = this.accountService.accountData.value.donateAmounts;
  currencyList: string[] = Object.entries(this.donateAmounts).map(([k, v]) => (k));
  cryptoWallets = this.accountService.accountData.value.cryptoWallets;
  cryptoCurrencyList: string[] = Object.entries(this.cryptoWallets).map(([k, v]) => (k));
  currentCurrency = this.currencyList[0];
  currentCryptoCurrency = this.cryptoCurrencyList[0];
  amount = this.donateAmounts[this.currentCurrency].default;
  donateInfo: any;

  subscriptions: Subscription[] = [];

  isDonateInfoLoading = new BehaviorSubject(false);

  paymentMethod: 'card' | 'crypto' = 'card';

  forWhatTips = [
    {title: 'Качественное обучение', description: 'Ваши донаты помогают нам создавать и обновлять курсы, улучшать материалы и обеспечивать поддержку студентов живыми людьми, а не ботами.'},
    {title: 'Развитие приложения и сайта', description: 'Мы инвестируем в улучшение сайта и приложения, делая обучение удобным и доступным. Ваша поддержка позволяет нам совершенствовать интерфейс, функционал и безопасность, над чем работает целая команда специалистов.'},
    {title: 'Стабильная работа', description: 'Ваши пожертвования покрывают оплату серверов, хранящих сотни гигабайт знаний, обеспечивая доступ к ним для 300 000+ учеников.  '},
  ];

  currentForWhatTip;

  donateMotivationText = '«О те, которые уверовали! Делайте пожертвования из того, чем Мы наделили вас»';


  constructor(private modalController: ModalController,
              private accountService: AccountService,
              private donateService: DonatesHttpRequestsService,
              private toastService: ToastService,
              private platform: Platform,
              private supportService: SupportService,
  ) {
    console.log(this.cryptoWallets);
    this.subscriptions.push(
      // works only in apps
      this.platform.resume.subscribe(() => {
        this.getDonateInfo();
      })
    );
  }

  getDonateLink() {
    this.isDonateInfoLoading.next(true);
    this.donateService.getDonateLink(this.amount, this.currentCurrency).subscribe(
      value => {
        if (value?.body?.result === 'success') {
          console.log('getDonateLink', value?.body?.result);
          this.donateInfo = {paymentId: value.body.paymentId};
        }

        console.log('getDonateLink', value);
        Browser.open({url: value.body.url, presentationStyle: 'popover'});
        // window.open(value.body.url);
        this.isDonateInfoLoading.next(false);
      }
    );
  }

  getDonateInfo() {
    this.isDonateInfoLoading.next(true);
    console.log('getDonateInfo', this.donateInfo);
    if (this.donateInfo) {
      this.donateService.getDonateInfo(this.donateInfo?.paymentId).pipe(
        finalize(() => this.isDonateInfoLoading.next(false)),
      ).subscribe(
        value => {
          console.log('getDonateInfo', value);
          if (value?.body?.id !== null) {
            this.donateInfo = value?.body;
          }


        }
      );
    }
    this.isDonateInfoLoading.next(false);

  }

  setAmount(newAmount: number) {
    this.amount = newAmount;
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent) {
    this.getDonateInfo();
  }

  async onPayClick() {
    this.getDonateLink();
  }

  onCurrencyChange(event) {
    this.amount = this.donateAmounts[event.detail.value].default;
  }

  async copyCryptoCode() {
    await Clipboard.write({
      string: this.cryptoWallets[this.currentCryptoCurrency]?.code
    });

    this.toastService.show({message: 'Код скопирован в буфер обмена!', color: 'success', duration: 500});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  closeModal() {
    this.modalController.dismiss();
  }

  onPaymentConfirmed() {
    this.subscriptions.push(
      of(true).pipe(
        delay(2000)
      ).subscribe(value => this.closeModal())
    );
  }

  openSupport() {
      this.supportService.openChatWithLoader();
  }



}
