
<div class="courseCertificateModalMainContainer">


  <section class="modalHeaderContainer" >
    <div class="modalHeader">
      <ion-icon (click)="closeModal()" name="close-outline"></ion-icon>
    </div>
  </section>

  <div class="modalContent" scrollShadow [top]="true" [bottom]="true">

    <section class="titleSection">
      <ion-text class="title">
        Поддержи Медину, <br>
        купив сертификат о <br>
        прохождении уровня или поделись информацией <br>
        об Академии с друзьями
      </ion-text>
    </section>

    <ion-text class="description">
      Это поможет нам оставаться бесплатными для наших учеников
    </ion-text>

    <section>
      <ion-img class="certificatePreviewImg" [src]="certificatePreviewUrl"></ion-img>

      <div class="buttonsContainer">
        <ion-button
          *skeleton="!certificateGetUrl; repeat: 1; height: '36px'; width: '100%'"
          (click)="closeModal()"
          [routerLink]="certificateGetUrl"
          color="primary"
          [fill]="'solid'">
          КУПИТЬ СЕРТИФИКАТ
        </ion-button>

        <ion-button (click)="openShareModal()" expand="block" color="primary" size="default" fill="clear">
          <div class="shareButtonContent">
            <ion-icon name="share-social-outline"></ion-icon>
            <ion-text>Рассказать друзьям о нас</ion-text>
          </div>

        </ion-button>
      </div>
    </section>
  </div>
</div>
