import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from '@capacitor/device';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {

  private _initialized: boolean = false;
  private _initializationPromise: Promise<any>;
  private _platform: string;

  constructor(
    private _router: Router,
  ) {
    this.firebaseInit().then(()=>{
      if(this._platform!=='web'){
        this._router.events.pipe(
          filter((e: RouterEvent) => e instanceof NavigationEnd),
        ).subscribe((e: RouterEvent) => {
          this.setScreenName(e.url, e.url);
        });
      }
    });
  }

  firebaseInit(): Promise<any>{
    if(this._initialized){
      return Promise.resolve();
    }

    if(this._initializationPromise){
      return this._initializationPromise;
    }

    this._initializationPromise = Device.getInfo().then(deviceInfo=>{
      this._platform = deviceInfo.platform;
      if(this._platform!='web'){
        return Promise.resolve();
      }
      return FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }).then(()=>{
      this._initialized = true;
      this._initializationPromise = null;
    });
    return this._initializationPromise;
  }

setUserId(userId:string|number):Promise<void>{
  /**
   * Platform: Web/Android/iOS
   * Sets the user ID property.
   * @param userId - unique identifier of a user
   * @returns void
   * https://firebase.google.com/docs/analytics/userid
   */

  return this.firebaseInit().then(()=>
    FirebaseAnalytics.setUserId({
      userId: userId.toString(),
    })
  );
}

setUserProperty(name:string,value:string|number): Promise<void>{
  /**
   * Platform: Web/Android/iOS
   * Sets a user property to a given value.
   * @param options - property name and value to set
   * @returns void
   * https://firebase.google.com/docs/analytics/user-properties
   */
  return this.firebaseInit().then(()=>
    FirebaseAnalytics.setUserProperty({
      name: name,
      value: value.toString(),
    })
  );
}

getAppInstanceId(): Promise<any>{
  if(this._platform=='web'){ //not implemented in WEB
    return Promise.reject();
  }
  /**
   * Platform: Android/iOS
   * Retrieves the app instance id from the service.
   * @param none
   * @returns instanceId - individual instance id value
   * https://firebase.google.com/docs/analytics/user-properties
   */
   return this.firebaseInit().then(()=>FirebaseAnalytics.getAppInstanceId());
}


setScreenName(name: string, nameOverride?:string): Promise<void>{
  /**
   * Platform: Android/iOS
   * Sets the current screen name, which specifies the current visual context in your app.
   * @param screenName - name of the current screen to track
   *        nameOverride - name of the screen class to override
   * @returns instanceId - individual instance id value
   * https://firebase.google.com/docs/analytics/screenviews
   */
  if(this._platform=='web'){ //not implemented in WEB
    return Promise.resolve();
  }
  return this.firebaseInit().then(()=>
    FirebaseAnalytics.setScreenName({
      screenName: name,
      nameOverride: nameOverride,
    })
  );
}

reset(): Promise<void>{
  /**
   * Platform: Web/Android/iOS
   * Clears all analytics data for this app from the device and resets the app instance id.
   * @param none
   * @returns void
   */
   if(this._platform=='web'){ //not implemented in WEB
     return Promise.resolve();
   }
   return this.firebaseInit().then(()=>FirebaseAnalytics.reset());
}

logEvent(name: string, params?:{ [key: string]: any }): Promise<void>{
  /**
   * Platform: Web/Android/iOS
   * Logs an app event.
   * @param name - name of the event to log
   *        params - key/value pairs of properties (25 maximum per event)
   * @returns void
   */
  return this.firebaseInit().then(()=>
    FirebaseAnalytics.logEvent({
      name: name,
      params: params,
    })
  );
}

setEnabled(value: boolean): Promise<void>{
  /**
   * Platform: Web/Android/iOS
   * Sets whether analytics collection is enabled for this app on this device.
   * @param name - enabled - boolean true/false
   * @returns void
   */
  return this.firebaseInit().then(()=>
    FirebaseAnalytics.setCollectionEnabled({
      enabled: value,
    })
  );
}

setSessionTimeoutDuration(durationInSeconds:number): Promise<void>{
  /**
   * Platform: Web/Android/iOS
   * Sets the duration of inactivity that terminates the current session.
   * @param duration - duration in seconds (default - 18000)
   * @returns void
   */
  return this.firebaseInit().then(()=>
    FirebaseAnalytics.setSessionTimeoutDuration({
      duration: durationInSeconds,
    })
  );
}

}
