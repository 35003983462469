import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { ExpandAnimations } from '../expand.animations';

@Component({
  selector: 'app-expandable-item',
  templateUrl: './expandable-item.component.html',
  styleUrls: ['./expandable-item.component.scss'],
  animations: ExpandAnimations
})
export class ExpandableItemComponent implements OnInit {
  @Input('expanded') _expanded: boolean = false;
  get expanded(){
    return this.expandable && this._expanded;
  }
  set expanded(value: boolean){
    this._expanded = value;
  }
  @Input() expandable: boolean = true;
  @Input('color') color: string = 'light';
//  @Input('lines') lines: 'inset' | 'full' | 'none' | null;

  @Output() onToggle: EventEmitter<boolean> = new EventEmitter();

  toggle(){
    if(!this.expandable){
      return;
    }
    this.expanded = !this.expanded;
    this.onToggle.emit(this.expanded);
    if(Capacitor.isPluginAvailable('Haptics')){
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }

  expand(){
    if(!this.expandable){
      return;
    }
    this.expanded = true;
    this.onToggle.emit(true);
  }

  collapse(){
    if(!this.expandable){
      return;
    }
    this.expanded = false;
    this.onToggle.emit(false);
  }

  constructor() { }

  ngOnInit() {}

}
