import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CustomPage } from '../../custom-page/custom-page.model';
import { delay, tap } from 'rxjs/operators';
import { EMPTY, of, throwError } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { DonateModalComponent } from '../donate-modal/donate-modal.component';
import { Browser } from '@capacitor/browser';
import { AccountService } from '../../account/account.service';

@Component({
  selector: 'app-page-modal',
  templateUrl: './page-modal.component.html',
  styleUrls: ['./page-modal.component.scss'],
})
export class PageModalComponent implements OnInit {

  @Input() initBannerInfo;

  page: CustomPage;
  isCloseButtonShow$ = of(true).pipe(delay(3000));
  constructor(private http: HttpClient,
              private modalController: ModalController,
              private accountService: AccountService) {



  }

  ngOnInit() {
    this.getPage(this.initBannerInfo.pageId).subscribe(response => {
      this.page = response.page;
    });
  }

  getPage(id) {

    // const url = `${environment.apiUrl}/getCustomPage`;
    const url = `https://medinaschool.org/api2/getCustomPage`;

    return this.http.post<{
      result: string,
      page: CustomPage
    }>(url, {id: id}).pipe(
      tap(response => {
        if (!response.result || response.result !== 'success' || !response.page) {
          throwError(EMPTY);
        }
        this.page = response.page;
      }),
      // finalize(()=>{
      //   this.isLoading = false;
      // })
    );
  }

  async openDonateModal() {
    this.closeModal();
    const accountData = this.accountService.accountData?.value;

    if (accountData.isDonateModal) {
      const modal = await this.modalController.create({
        component: DonateModalComponent,
        breakpoints: [0, 0.3, 0.5, 0.9, 1, 3],
        initialBreakpoint: 0.9,
        cssClass: 'sheet_modal'
      });

      return await modal.present();
    } else {
      Browser.open({url: accountData.donationLink, presentationStyle: 'popover'});
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
