import { animate, group, query, state, style, transition, trigger } from "@angular/animations";

export const MainToolbarAnimations = [
  trigger('palmBgInOut', [
    state('true',style({
      transform: 'translateY(0)',
      visibility: 'visible'
    })),
    state('false',style({
      transform: 'translateY(-25%)',
      visibility: 'hidden',
    })),
    transition('false <=> true', [

        animate('300ms ease-in')
    ]),
  ]),

  trigger('palmInOut', [
    state('false',style({
      transform: 'translateY(-25%)',
      opacity: 0,
      visibility: 'hidden'
    })),
    state('true',style({
      transform: 'translateY(0)',
      opacity: 1,
      visibility: 'visible'
    })),
    transition('false => true', [
      group([
        animate('300ms ease-in'),
        query('.palm-content', [
          style({ opacity: 0 }),
          animate('200ms 100ms ease-in')
        ]),
      ]),
    ]),
    transition('true => false', [
      group([
        animate('300ms ease-in', style({
          transform: 'translateY(-25%)',
        })),
        query('.palm-content', [
          animate('200ms 100ms ease-in',style({ opacity: 0 }))
        ]),
      ])
    ])
  ]),
];
