import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FirebaseRemoteConfig } from '@joinflux/firebase-remote-config';
import { environment } from 'src/environments/environment';

import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getBoolean, getNumber, getRemoteConfig, getString, RemoteConfig } from 'firebase/remote-config';

@Injectable({
  providedIn: 'root'
})
export class FirebaseRemoteConfigService {

  private _webRemoteConfig: RemoteConfig;
  private _initPromise: Promise<any>;

  constructor() {
    this.init();
  }

  init(){
    this._initPromise = this.getInitPromise();
    this._initPromise.then(()=>{
      this._initPromise = Promise.resolve();
    });
  }

  getInitPromise(): Promise<any>{
    const minimumFetchInterval = 10; //seconds
    const fetchTimeout = 60; //seconds
    if(Capacitor.isNativePlatform()){ //iOS or Android:
      return this._initPromise = FirebaseRemoteConfig.initialize({
        minimumFetchInterval: minimumFetchInterval,
        fetchTimeout: fetchTimeout,
      }).then(()=>FirebaseRemoteConfig.fetchAndActivate());
    }
    //web:
    const fb = initializeApp(environment.firebaseConfig);
    this._webRemoteConfig = getRemoteConfig(fb);
    this._webRemoteConfig.settings = {
      fetchTimeoutMillis: fetchTimeout*1000,
      minimumFetchIntervalMillis: minimumFetchInterval*1000,
    };
    return fetchAndActivate(this._webRemoteConfig);
  }

  getString(key: string): Promise<string>{
    return this._initPromise.then(()=>Capacitor.isNativePlatform()
      ? FirebaseRemoteConfig.getString({key: key}).then(data=>data.value)
      : Promise.resolve(getString(this._webRemoteConfig, key))
    );
  }

  getNumber(key: string): Promise<number>{
    return this._initPromise.then(()=>Capacitor.isNativePlatform()
      ? FirebaseRemoteConfig.getNumber({key: key}).then(data=>+data.value)
      : Promise.resolve(getNumber(this._webRemoteConfig, key))
    );
  }

  getBoolean(key: string): Promise<boolean>{
    return this._initPromise.then(()=>Capacitor.isNativePlatform()
      ? FirebaseRemoteConfig.getBoolean({key: key}).then(data=>{
        return !!data.value;
      })
      : Promise.resolve(getBoolean(this._webRemoteConfig, key))
    );
  }
}
