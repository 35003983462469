import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { FirebaseAnalyticsService } from 'src/app/shared/services/firebase-analytics.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CourseModel } from '../shared/Models/courses-models/course.model';
import { delay, tap } from 'rxjs/operators';
import { CoursesService } from '../shared/services/courses.service';
import { PlayerType } from '../player/player-type.model';
import { PlayerTypeListModel } from '../player/player-type-list-model';

@Injectable({
  providedIn: 'root'
})
export class PlayService implements OnDestroy {

  contentId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  $courseId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  $courseInfo: BehaviorSubject<CourseModel> = new BehaviorSubject<CourseModel>(null);
  $courseInfoLoading = new BehaviorSubject(true);
  subscriptions: Subscription[] = [];

  $currentPlayerType = new BehaviorSubject<PlayerType>('video');

  $currentPlayerTypes: BehaviorSubject<PlayerTypeListModel[]> = new BehaviorSubject<PlayerTypeListModel[]>([
      {
        label: 'Видео',
        disabled: true,
        value: 'video'
      },
      {
        label: 'Аудио',
        disabled: true,
        value: 'audio'
      },
      {
        label: 'Интерактивное видео',
        disabled: true,
        value: 'interactive'
      },
    ],
  );

  constructor(
    private _storageService: StorageService,
    private _firebaseAnalyticsService: FirebaseAnalyticsService,
    private coursesService: CoursesService,
  ) {
    this.subscriptions.push(
      this.$courseId.subscribe(courseId => this.getCourseInfo(courseId)),
      this.$currentPlayerType.subscribe(value => {
        // console.log('this._storage.set', value.toString());
        this._storageService.set('selectedPlayer', value.toString());
      })
    );

    this._storageService.get('lastContentId').then(lastContentId => {
      if (lastContentId) {
        this.contentId.next(+lastContentId);
      }
      this.subscriptions.push(
        this.contentId.subscribe(contentId => {
          if (!contentId) {
            return;
          }
          this._storageService.set('lastContentId', contentId.toString());
          this._firebaseAnalyticsService.logEvent('lessonOpened', {
            id: contentId
          });
        })
      );
    });
  }

  getCourseInfo(courseId: number) {
    if (courseId) {
      this.subscriptions.push(
        this.coursesService.getCourseInfoById(this.$courseId.value).pipe(
          tap(() => this.$courseInfoLoading.next(false)),
        ).subscribe(courseInfo => this.$courseInfo.next(courseInfo))
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
