<div class="paymentProgressContainer">
  <div class="stepsContainer">
    <div class="step" *ngFor="let step of steps; let i = index">
      <ion-text [class.active-color]="i <= currentStep">{{step.name}}</ion-text>
      <div class="step-bottom">

        <div class="step-connector"
             [class.step-connector-hidden]="i === 0"
             [class.active-background]="i <= currentStep"></div>

        <div class="step-number"
             [class.step-number-active]="i <= currentStep">
          {{step.number}}
        </div>

        <div class="step-connector"
             [class.step-connector-hidden]="i === steps.length-1"
             [class.active-background]="currentStep > i "></div>
      </div>

    </div>
  </div>

</div>
