import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimAround'
})
export class TrimAroundPipe implements PipeTransform {

  transform(text: string, search: string, maxLength: number): string {
    if(maxLength>=text.length){
      return text;
    }
    const regExp = new RegExp(search, "ig");
    const searchPos = text.search(regExp);
    if(searchPos<0){
      return text.substr(0,maxLength) + '...';
    }
    const searchLength = search.length;
    let beforeLength = Math.floor( (maxLength - searchLength) / 2 );
    if(searchPos<beforeLength){
      beforeLength = searchPos;
    }
    const afterLength = maxLength - searchLength - beforeLength;
    const startPos = searchPos - beforeLength;
    let result = text.substr(startPos, beforeLength + searchLength + afterLength);
    if(text.length - startPos > result.length){
      result += '...';
    }
    if(searchPos>beforeLength){
      result = '...' + result;
    }
    return result;
  }

}
