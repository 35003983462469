import { AfterViewInit, Directive, ElementRef } from '@angular/core'

@Directive({
  selector: '[appAutofocus]'
})
export class AutoFocus implements AfterViewInit {

  constructor(
    private elementRef: ElementRef
  ) {
  }

  ngAfterViewInit() {
    console.log(this.elementRef.nativeElement);
    this.elementRef.nativeElement.focus();
  }
}
