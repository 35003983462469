import { animate, style, transition, trigger } from "@angular/animations";
export const ExpandAnimations = [
  trigger('expand', [
    transition('void => *', [
      style({
        maxHeight: '0px',
        opacity: 0,
        overflow: 'hidden'

      }),
      animate(500,style({
        maxHeight: '100vh',
        opacity: 1
      })),
      style({
        maxHeight: ''
      })
    ]),
    transition('* => void', [
      style({
        overflow: 'hidden',
        height: '*',
        opacity: 1

      }),
      animate(150,style({
        height: 0,
        opacity: 0
      })),
    ])
  ]),
]
