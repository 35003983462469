import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExamStateService {
  _examStarted = false;

  constructor(
  ) { }

  public get examStarted(){
    return this._examStarted;
  }

  public set examStarted(value:boolean){
    this._examStarted = value;
  }




}
