import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Card } from './card.model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input('card') card: Card;
  @Input() grayscaled: boolean = false;
  cardStyle: {};

  @HostBinding('hidden')
  isHidden:boolean = false;

  constructor() { }

  ngOnInit() {

    if(!this.card.badgeStyle){
      this.card.badgeStyle = 'color';
    }
    if(!this.card.style){
      this.card.style = 'light';
    }
    if(!this.card.imageStyle){
      this.card.imageStyle = 'corner';
    }

    this.cardStyle = {
      'background-color': this.card.backgroundColor ?? null,
      'background-image': this.card.image && this.card.imageStyle!='imageOnly' ? `url(${this.card.image})` : null,
      '--card-color': this.card.color,
      'opacity': this.grayscaled ? this.opacityLevel : null
    };
  }

  get opacityLevel(): string{
    if(this.card.imageStyle && this.card.imageStyle=='imageOnly'){
      return '50%';
    }
    return this.card.style=='light' ? '70%' : '40%';
  }

  onClick(){
    if(this.card.externalLink){
      window.open(this.card.externalLink, '_system');
    }
    if(this.card.onClick){
      this.card.onClick();
    }

    if (this.card.callBack){
      this.card.callBack.emit();
    }

  }

}
