import {Injectable} from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AmplitudeService {

    constructor() {
        amplitude.init(environment.amplitudeApiKey, {defaultTracking: false});
    }

    // trackSignUp() {
    //     amplitude.track('Sign Up');
    // }

    trackEvent(event: AmplitudeEvent): void {
        // console.log(event);
        amplitude.track(event.eventInput, event.eventProperties);
    }
}

export interface AmplitudeEvent {
    eventInput: AmplitudeEventsEnum;
    eventProperties?: Record<string, any> | undefined;
    eventOptions?: any;
}

export enum AmplitudeEventsEnum {
    appOpen = 'App Open',
    authPage = 'Auth Page', // +
    authStart = 'Auth Start', // +
    authNeedHelp = 'Auth Need Help', // +
    forgotEmail = 'Forgot Email', // +
    forgotPassword = 'Forgot Password', // +
    authSuccess = 'Auth Success', // +
    mainPage = 'Main Page', // +
    myProgress = 'My Progress', // +
    tapCourse = 'Tap Course', // +
    currentLesson = 'Current Lesson', // +
    coursePage = 'Course Page', // +
    lessonPage = 'Lesson Page', // +
    lessonContent = 'Lesson Content', // +
    questions = 'Questions', // +
    lessonsList = 'Lessons List', // +
    fullText = 'Full Text', // +
    aboutSubject = 'About Subject', // +
    examPage = 'Exam Page', // +
    examStart = 'Exam Start', // +
    examResult = 'Exam Result', // +
    congratulate = 'Congratulate', // +
    ramadanPage2025 = 'Ramadan Page 2025', // +
}

export enum AmplitudePropertiesEnum {
    sourceOpen = 'Source Open',
    authType = 'Auth Type',
    screen = 'Screen',
    typeAuth = 'Type Auth',
    courseLevel = 'Course Level',
    courseName = 'Course Name',
    lessonName = 'Lesson Name',
    lessonNumber = 'Lesson Number',
    viewedLessons = 'Viewed Lessons',
    answers = 'Answers',
    correctAnswers = 'Correct Answers',
    passingBall = 'Passing Ball',
    yourResult = 'Your Result',
}
