<ion-card
  [routerLink]="result.routerLink"
  [class.unavailable]="!result.available"
  (click)="onClick()"
  [@shake]="isShaking" (@shake.done)="onShakeFinished()">
    <!-- <app-video-thumbnail *ngIf="result.image" [img]="result.image"></app-video-thumbnail> -->
    <ion-card-header>
      <ion-card-subtitle *ngIf="searchType=='lessons' && result.level && result.cat_title">
        {{result.cat_title}} ({{result.level}}-й уровень)
      </ion-card-subtitle>
      <ion-card-subtitle *ngIf="searchType=='webinars' && result.lector" [innerHtml]="result.lector | matchMarker:searchQuery"></ion-card-subtitle>
      <ion-card-subtitle *ngIf="searchType=='news' && result.date">
        {{result.date | niceDate}}
      </ion-card-subtitle>
      <ion-card-title [innerHtml]="result.title | matchMarker:searchQuery"></ion-card-title>
    </ion-card-header>
    <ion-card-content [innerHtml]="result.text | stripTags | trimAround:searchQuery:150 | matchMarker:searchQuery">
    </ion-card-content>
  </ion-card>
