import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { delay, map, switchMap } from 'rxjs/operators';
import { CoursesService } from '../services/courses.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements AfterViewInit {
  @ViewChild('searchInput') myInputField: ElementRef;
  searchControl = new FormControl<string>(null);

  courses$ = this.searchControl.valueChanges.pipe(
    switchMap(searchString =>
      this.coursesService.allCourses$.pipe(
        map(courses =>
          courses.filter(course =>
            course.title.toLowerCase().includes(searchString.toLowerCase())
            || course.short_description.toLowerCase().includes(searchString.toLowerCase())
            || course.teacher_name.toLowerCase().includes(searchString.toLowerCase())
          )
        ),
      )
    ),
  );

  constructor(private modalController: ModalController,
              private coursesService: CoursesService) {
  }

  ngAfterViewInit() {
    of(true).pipe(delay(100)).subscribe(() => {
        this.myInputField.nativeElement.focus();
      }
    );
  }

  closeSearchModal() {
    this.modalController.dismiss();
  }

  test() {
    this.myInputField.nativeElement.focus();
  }

}
