import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShareModalComponent } from '../../shared/share-modal/share-modal.component';
import { ModalController, NavController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PlayerType } from '../../player/player-type.model';
import { PlayService } from '../play.service';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsModalComponent {

  $courseInfoLoading = this._playService.$courseInfoLoading;

  $courseInfo = this._playService.$courseInfo;
  $currentPlayerType = this._playService.$currentPlayerType.pipe(
    tap(type => console.log('type', type))
  );
  subscriptions: Subscription[] = [];

  $playerTypes = this._playService.$currentPlayerTypes;

  constructor(private modalController: ModalController,
              private _playService: PlayService,
              private _navController: NavController) {
  }

  navigateToCoursePage() {
    this.closeShareModal();
    this._navController.navigateForward(`/course/${this.$courseInfo.value.url}`);
  }

  async openShareModal() {
    this.closeShareModal();
    const modal = await this.modalController.create({
      component: ShareModalComponent,
      cssClass: 'modal_share',
      componentProps: {
        url: `/course/${this.$courseInfo.value.url}`,
        title: `Поделись курсом \n ${this.$courseInfo.value.title} с друзьями!`,
        shareText: `Пройди курс "${this.$courseInfo.value.title}" вместе с Академией Медина!`,
      }
    });

    return await modal.present();
  }

  closeShareModal() {
    this.modalController.dismiss();
  }

  selectPlayer(selectedPlayer: PlayerType) {
    this.closeShareModal();
    this._playService.$currentPlayerType.next(selectedPlayer);
  }

}
