<ion-content color="light">
  <div class="courses-category-page">

    <div class="courses-category-page-content">

      <section class="searchContainer">
        <div class="backButton"
             (click)="closeSearchModal()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16.7233 3.27505C16.3543 2.90832 15.756 2.90832 15.387 3.27505L7.27674 11.336C6.90775 11.7027 6.90775 12.2973 7.27674 12.664L15.387 20.7249C15.756 21.0917 16.3543 21.0917 16.7233 20.7249C17.0922 20.3582 17.0922 19.7636 16.7233 19.3969L9.28107 12L16.7233 4.60314C17.0922 4.2364 17.0922 3.64179 16.7233 3.27505Z"
                  fill="#070C1C"/>
          </svg>
        </div>

        <div class="searchInputContainer">
          <div class="searchIcon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1395_8610)">
                <circle cx="7.66659" cy="7.66659" r="6.33333" stroke="#B1B6BB" stroke-width="1.5"/>
                <path d="M12.3333 12.3333L14.6666 14.6666" stroke="#B1B6BB" stroke-width="1.5" stroke-linecap="round"/>
              </g>
              <defs>
                <clipPath id="clip0_1395_8610">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>

          <input type="search"
                 #searchInput
                 id="searchInput"
                 placeholder="Поиск"
                 [formControl]="searchControl">

          <ng-container *ngIf="searchControl.value">
            <div class="clearSearchButton"
                 (click)="searchControl.setValue(null)">
              <svg
                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 7.00004L12 12M12 12L7.00004 17M12 12L7 7M12 12L17 17" stroke="#1C274C" stroke-opacity="0.3"
                      stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>

          </ng-container>


        </div>
        <!--        <div class="filtersButton">-->
        <!--        </div>-->
      </section>

      <span class="foundedCountLabel" *ngIf="(courses$ | async)?.length">
        Найдено: {{(courses$ | async)?.length}}
      </span>

      <section class="coursesContainer">
        <app-course-card *ngFor="let course of (courses$ | async)"
                         (click)="closeSearchModal()"
                         [card]="course">
        </app-course-card>
      </section>

    </div>

  </div>
</ion-content>
