<ion-item [class.invalidField]="formController.invalid && formController.dirty" >
  <ion-label *ngIf="title" position="floating">
    {{ title }}
  </ion-label>
  <ion-input #textInput
             [autocomplete]="autoComplete"
             [type]="isShowPassword ? 'text' : type"
             [(ngModel)]="value"
             clearOnEdit="false"
             [readonly]="readonly">
  </ion-input>
  <ion-button slot="end"
              expand="block"
              size="small"
              fill="clear"
              class="passwordToggleButton"
              *ngIf="type === 'password'"
              (click)="this.isShowPassword = !this.isShowPassword">
    <ion-icon [name]="this.isShowPassword ? 'eye' : 'eye-off'">
    </ion-icon>
  </ion-button>

</ion-item>
<ion-text *ngIf="helperText"
          class="helperText">
  {{ helperText }}
</ion-text>
