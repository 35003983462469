import { Pipe, PipeTransform, SecurityContext, VERSION } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'matchMarker'
})
export class MatchMarkerPipe implements PipeTransform {
  constructor(
    private _sanitizer: DomSanitizer
  ){}

  transform(text: string, search: string): SafeHtml {
    const searchRexEx = new RegExp('('+search+')', "ig");
    const result = text.replace(searchRexEx,"<marker>$1</marker>");
    return this._sanitizer.bypassSecurityTrustHtml(result);
  }

}
